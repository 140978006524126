import React, {ReactElement, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp, faDownload} from "@fortawesome/free-solid-svg-icons";

export function Accordion(props: any): ReactElement {
    const [showBody, setShowBody] = useState<boolean>(props.showBody ?? false);
    const title = props.title;
    const children = props.children;

    return (
        <div className={"Accordion"}>
            <div className={"AccordionHeader"}
                 style={{padding: "10px 10px 10px 10px", cursor: "pointer", borderRadius: "8px"}}
                 onClick={() => setShowBody(!showBody)}>
                <div className={"Name"}>{title}</div>
                <div style={{cursor: "pointer"}}>
                    {showBody
                        ? <FontAwesomeIcon icon={faChevronUp}/>
                        : <FontAwesomeIcon icon={faChevronDown}/>
                    }
                </div>
            </div>
            {showBody
                ? <div className={"AccordionBody"} style={{padding: "0px 0px 5px 0px"}}>
                    {children}
                </div>
                : ""
            }
        </div>
    );
}
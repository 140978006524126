import React, {ChangeEvent, ReactElement, useRef, useState} from "react";
import {REST} from "./REST";
import './css/otb.css';
import {Loader} from "./Loader";
import {OtbJwt} from "./types/OtbJwt";

export function LoginPage(props: any): ReactElement {
    const title: string = props.title;
    const version: string = process.env.REACT_APP_VERSION ?? '0.0.0';
    const username = useRef<HTMLInputElement | null>(null);
    const password = useRef<HTMLInputElement | null>(null);
    const newPassword = useRef<HTMLInputElement | null>(null);
    const [error, hasError] = useState<string | null>(null);
    const [needChangePassword, setNeedChangePassword] = useState<boolean>(false);
    const [passwordEqual, setPasswordEqual] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const auth = () => {
        hasError(null);
        setLoading(true);
        REST.login(
            username.current?.value ?? '',
            password.current?.value ?? ''
        ).then((jwt: OtbJwt) => {
            setLoading(false);
            if(jwt.user.need_change_password) {
                username.current!.value = '';
                password.current!.value = '';
                setNeedChangePassword(true);
            } else {
                document.location = "/";
            }
        }).catch((error: Error) => {
            setLoading(false);
            hasError(error.message);
        });
    }

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        if(password.current!.value === newPassword.current!.value && password.current!.value.length > 0) {
            setPasswordEqual(true);
        } else {
            setPasswordEqual(false);
        }
    }

    if(needChangePassword) {
        return (<main style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100vh", flexDirection: "column"}}>
                <section className={"LoginPage"}>

                    <h4>{title}</h4>

                    <input className={"LoginInput"} placeholder="Новый пароль" type="password" ref={password}
                           name={"newPassword"} onChange={handleOnChange} defaultValue={""}/>
                    <input className={"LoginInput"} placeholder="Повторите пароль" type="password" ref={newPassword}
                           name={"repeatPassword"} onChange={handleOnChange} defaultValue={""}/>

                    <div className={"Button Primary LoginButton" + (passwordEqual ? "" : " Disabled")} style={{height: "36px"}} onClick={(e) => {
                        e.preventDefault();
                        if (passwordEqual) {
                            REST.changePassword(newPassword.current!.value).then(()=> document.location = "/");
                        }
                    }}>{loading
                        ? <Loader color={"var(--otb-foreground)"}/>
                        : "Сменить пароль"
                    }
                    </div>
                </section>
                <div style={{
                    color: "rgba(129, 140, 153, 0.5)",
                    width: "288px",
                    textAlign: "right",
                    paddingRight: "12px",
                    paddingLeft: "12px"
                }}>v{version}</div>
            </main>
        )
    }

    return (<main style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100vh", flexDirection: "column"}}>
            <section className={"LoginPage"}>

                <h4>{title}</h4>

                <input className={"LoginInput"} placeholder="Логин" type="text" ref={username} name={"username"}
                       onChange={() => hasError(null)}/>
                <input className={"LoginInput"} placeholder="Пароль" type="password" ref={password} name={"password"}
                       autoComplete={"current-password"}
                       onChange={() => hasError(null)}/>

                {error
                    ? <div className={"LoginError"}>{error}</div>
                    : ""
                }

                <div className={"Button Primary LoginButton"} style={{height: "36px"}} onClick={(e) => {
                    e.preventDefault();
                    auth();
                }}>{loading
                    ? <Loader color={"var(--otb-foreground)"} />
                    :"Войти"
                }
                </div>
            </section>
            <div style={{color: "rgba(129, 140, 153, 0.5)", width: "288px", textAlign: "right", paddingRight: "12px", paddingLeft: "12px"}}>v{version}</div>
        </main>
    )
}

import React, {ChangeEventHandler, ReactElement, useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {DogovorType} from "../types/DogovorType";
import {REST} from "../REST";
import {CompanyType} from "../types/CompanyType";

export function AddContractModal(props: any): ReactElement {
    const [visible, setVisible] = props.visibleState;
    const [currentContract, setCurrentContract] = props.contractState as [DogovorType | null, React.Dispatch<React.SetStateAction<DogovorType | null>>];
    const [canSave, setCanSave] = useState<boolean>(false);
    const [companies, setCompanies] = useState<CompanyType[]>([]);

    const customer = useRef<HTMLSelectElement>(null);
    const executor = useRef<HTMLSelectElement>(null);
    const number = useRef<HTMLInputElement>(null);
    const start = useRef<HTMLInputElement>(null);
    const end = useRef<HTMLInputElement>(null);

    const hideMe = () => {
        setCurrentContract(null);
        setVisible(false);
    }

    useEffect(() => {
        REST.getCompanies().then((c: CompanyType[]) => {
            setCompanies(c)
        });
    }, []);

    if (!visible) {
        return (<></>);
    }

    /*const handleInnChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        if (event.target.value.length === 10 || event.target.value.length === 12) {
            REST.getCompanyByInn(parseInt(event.target.value), true).then(c => {
                if (event.target.name === "customer") {
                    customerName.current!.value = c.name ?? ""
                } else if (event.target.name === "executor") {
                    executorName.current!.value = c.name ?? ""
                }
                handleOnChange();
            })
        } else {
            if (event.target.name === "customer") {
                customerName.current!.value = ""
            } else if (event.target.name === "executor") {
                executorName.current!.value = ""
            }
            handleOnChange();
        }
    }*/

    const handleOnChange = () => {
        setCanSave((number.current?.value.length ?? 0) > 0 &&
            (customer.current?.value.length ?? 0) > 0 &&
            (executor.current?.value.length ?? 0) > 0 &&
            (start.current?.value.length ?? 0) > 0 &&
            (end.current?.value.length ?? 0) > 0);
    }

    const addContract = () => {
        let contract = {
            number: number.current!.value,
            start: start.current!.value,
            end: end.current!.value,
            customer: {inn: BigInt(customer.current!.value)},
            executor: {inn: BigInt(executor.current!.value)},
        }
        REST.addDogovor(contract).then((d: DogovorType) => {
            hideMe();
        });
    }

    const extendContract = () => {
        REST.extendDogovor(currentContract!.id).then((d: DogovorType) => {
            hideMe();
        });
    }

    return (
        <div className={"ModalBackground"} onClick={() => hideMe()}>
            <div className={"Modal"} onClick={(e) => e.stopPropagation()}>
                <div className={"ModalHeader"} style={{justifyContent: "space-between"}}>

                    {currentContract !== null
                        ? <>Договор
                            №{currentContract?.number} от {currentContract?.formattedStart}{(currentContract?.end ?? 0) < Date.now() ?
                                <b style={{color: "#ff3347"}}>Завершен</b> : ""}</>
                        : "Добавление договора"}

                    <div onClick={() => hideMe()} style={{cursor: "pointer"}}>
                        <FontAwesomeIcon icon={faXmark}/>
                    </div>
                </div>

                <div className={"ModalBody"}>

                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Номер договора</span>
                        {currentContract !== null
                            ? <div className="form-control">{currentContract?.number}</div>
                            :
                            <input type="text" className="form-control" ref={number} onChange={() => handleOnChange()}/>
                        }
                    </div>

                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Срок действия договора</span>
                        {currentContract !== null
                            ? <div className="form-control">{currentContract?.formattedStart}</div>
                            :
                            <input type="date" className="form-control" ref={start} onChange={() => handleOnChange()}/>
                        }
                        <span className="input-group-text">&nbsp;-&nbsp;</span>
                        {currentContract !== null
                            ? <div className="form-control">{currentContract?.formattedEnd}</div>
                            : <input type="date" className="form-control" ref={end} onChange={() => handleOnChange()}/>
                        }
                    </div>

                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Заказчик</span>
                        {currentContract !== null
                            ? <div className="form-control">{currentContract?.customer.name}</div>
                            : <select className="form-control" ref={customer} onChange={() => handleOnChange()}>
                                    {companies!.filter(c => !c.is_closed).map(c => <option key={c.inn} value={c.inn}>{c.name + " (" + c.inn + ")"}</option>)}
                                </select>
                        }
                    </div>

                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Исполнитель</span>
                        {currentContract !== null
                            ? <div className="form-control">{currentContract?.executor.name}</div>
                            : <select className="form-control" ref={executor} onChange={() => handleOnChange()}>
                                {companies!.filter(c => !c.is_closed).map(c => <option key={c.inn}
                                                             value={c.inn}>{c.name + " (" + c.inn + ")"}</option>)}
                            </select>
                        }
                    </div>


                </div>

                <div className={"ModalFooter"}>
                    {currentContract !== null
                        ? <div className={"Button Primary"} onClick={() => extendContract()}>Продлить</div>
                        : <div className={"Button Primary" + (canSave ? "" : " Disabled")} onClick={() => {
                            if (canSave) addContract();
                        }}>Создать
                        </div>
                    }

                    <div className="Button" style={{marginLeft: "10px"}}
                         onClick={() => hideMe()}>Отмена
                    </div>
                </div>

            </div>
        </div>
    );
}
import React, {ReactElement, useEffect, useState} from "react";
import {REST} from "../../REST";
import {PassportType} from "../../types/PassportType";
import {Loader} from "../../Loader";

export function TabPassports(props: any): ReactElement {
    const [passports, setPassports] = useState<PassportType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        REST.getPassports().then((p: PassportType[]) => {
            setPassports(p);
            setLoading(false);
        });
    }, [loading]);

    if (loading) {
        return (<div className={"tab-pane fade show active"} id="nav-passports" role="tabpanel"
                     aria-labelledby="nav-passports-tab" style={{backgroundColor: "white"}}><Loader/></div>);
    }

    return (
        <div className={"tab-pane fade show active"} id="nav-passports" role="tabpanel" aria-labelledby="nav-passports-tab" style={{backgroundColor: "white"}}>
            <div style={{paddingTop: "10px", paddingBottom: "10px", display: "flex"}}>
                <a href='/water/passport' className="btn btn-primary btn-sm" type="button">Создать паспорт</a>
            </div>
            <div className="list-group">
                {passports.length === 0
                    ? <span className="list-group-item text-center">Нет паспортов ОТБ ТС</span>
                    : passports.map((p: PassportType) => <a key={p.id} href={"/water/passport/" + p.id}
                                                            className="list-group-item list-group-item-action list-group-item-default">{"Паспорт № " + p.id}</a>)
                }
            </div>
        </div>
    );
}

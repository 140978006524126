import React, {ReactElement} from "react";
import {EmployeeType} from "../types/EmployeeType";
import {Utils} from "../utils/Utils";
import {TabOperatorContracts} from "../component/part/TabOperatorContracts";
import {Navigate} from "react-router-dom";

export function Main(props: any): ReactElement {
    const me: EmployeeType = props.me;

    if (Utils.hasRole("road")) {
        return <Navigate to={"/road"}/>
    }
    if (Utils.hasRole("water")) {
        return <Navigate to={"/water"}/>
    }
    /*if (Utils.hasRole("rail")) {
        return <Navigate to={"/rail"}/>
    }*/
    /*if (Utils.hasRole("air")) {
        return <Navigate to={"/air"}/>
    }*/
    if (Utils.hasRole("admin")) {
        return <Navigate to={"/admin"}/>
    }


    return (<div className={'main-page'}>

            <div className="nav nav-tabs" id="nav-tab" role="tablist">

                {Utils.hasRole("operator") &&
                    <button className="nav-link" id="nav-operator-contracts-tab" data-bs-toggle="tab"
                            data-bs-target="#nav-operator-contracts" type="button" role="tab"
                            aria-controls="nav-profile"
                            aria-selected="false">Контракты
                    </button>
                }

            </div>

            <div className="tab-content" id="nav-tabContent">

                {Utils.hasRole("operator") ? <TabOperatorContracts me={me}/> : ""}

            </div>
        </div>
    );
}

import {EmployeeType} from "../types/EmployeeType";

export class Utils {
    public static hasRole(role: string): boolean {
        const me: EmployeeType = JSON.parse(sessionStorage.getItem("me") ?? '{}');
        for (let i = 0; i < me.roles.length; i++) {
            if (me.roles[i].code === role) {
                return true;
            }
        }
        return false;
    }

    public static makeFIO(fio: string): string {
        let ff: string[] = fio.split(' ');
        return ff[0] + ' ' + ff.slice(1).map(f => f.substring(0, 1)).join('.') + '.';
    }
}
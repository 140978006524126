import React, {ReactElement, useEffect, useState} from "react";
import {CountryType} from "../types/CountryType";
import {REST} from "../REST";
import {Loader} from "../Loader";

export function PassportPage(props: any): ReactElement {
    const [countries, setCountries] = useState<CountryType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        REST.getCountry().then((c: CountryType[]) => {
            setCountries(c);
            setLoading(false);
        })
    }, []);

    if (loading) {
        return (<div><Loader/></div>)
    }

    return (<section className="container">
            <div className={"alert alert-danger text-center"}>Данный раздел находится в работе</div>
            <div className="input-group mb-3">
                <span className="input-group-text">Полное название СТИ</span>
                <input type="text" className="form-control" id="name_sti" name="name_sti" required/>
                <span className="input-group-text">Руководитель СТИ</span>
                <input type="text" className="form-control" id="director_sti" name="director_sti" required/>
            </div>
            <hr/>
            <br/>
            <div className="input-group mb-3">
                <span className="input-group-text">Субъект транспортной инфраструктуры</span>
                <input type="text" className="form-control" id="subject" name="subject" required/>
            </div>
            <div className="input-group mb-3">
                <span className="input-group-text">Название транспортного средства</span>
                <input type="text" className="form-control" id="name_ts" name="name_ts" required/>
            </div>
            <div className="input-group mb-3">
                <span className="input-group-text">ИМО</span>
                <input type="text" className="form-control" id="imonumber" name="imonumber" required/>
                <span className="input-group-text">Реестровый номер транспортного средства</span>
                <input type="text" className="form-control" id="reestrnumber" name="reestrnumber" required/>
            </div>

            <hr/>
            <h5>1. Общие сведения о субъекте транспортной инфраструктуры (перевозчике)</h5>
            <div className="input-group mb-3">
                <span className="input-group-text">Полное наименование, организационно-правовая форма по ОКОПФ</span>
                TODO: https://classifikators.ru/okopf
                <input type="text" className="form-control" id="id1" name="id1" required/>
            </div>
            <div className="input-group mb-3">
                <span className="input-group-text">Краткое наименование, организационно-правовая форма по ОКОПФ</span>
                TODO: https://classifikators.ru/okopf
                <input type="text" className="form-control" id="id1" name="id1" required/>
            </div>
            <div className="input-group mb-3">
                <span className="input-group-text">Адрес, указанный в ЕГРЮЛ</span>
                <input type="text" className="form-control" id="id1" name="id1" required/>
            </div>
            <div className="input-group mb-3">
                <span className="input-group-text">Фактический адрес</span>
                <input type="text" className="form-control" id="id1" name="id1" required/>
            </div>
            <div className="input-group mb-3">
                <span className="input-group-text">Контактные данные:</span>
                <span className="input-group-text">телефон</span>
                <input type="text" className="form-control" id="id1" name="id1" required/>
                <span className="input-group-text">факс</span>
                <input type="text" className="form-control" id="id1" name="id1" required/>
                <span className="input-group-text">адрес электронной почты</span>
                <input type="text" className="form-control" id="id1" name="id1" required/>
            </div>
            <div className="input-group mb-3">
                <span className="input-group-text">Регистрационный номер и дата внесения в ЕГРЮЛ</span>
                <input type="text" className="form-control" id="id1" name="id1" required/>
            </div>
            <div className="input-group mb-3">
                <span className="input-group-text">ИНН и дата его присвоения</span>
                <input type="text" className="form-control" id="id1" name="id1" required/>
            </div>

            <hr/>
            <h5>2. Сведения о транспортном средстве и об осуществляемых перевозках</h5>
            <div className="input-group mb-3">
                <span className="input-group-text">Название судна или номер</span>
                <input type="text" className="form-control" id="id1" name="id1" required/>
                <span className="input-group-text">Государство регистрации</span>
                <select className="form-select" id="country" name="country" required>
                    {countries.map((c: CountryType) => <option value={c.id} key={c.id}>{c.name}</option>)}
                </select>
            </div>
            <div className="input-group mb-3">
                <span className="input-group-text">Регистрационный номер судна в соответствующем судовом реестре</span>
                <input type="text" className="form-control" id="id1" name="id1" required/>
            </div>
            <div className="input-group mb-3">
                <span className="input-group-text">ИМО</span>
                <input type="text" className="form-control" id="id1" name="id1" required/>
                <span className="input-group-text">Государственный флаг</span>
                <select className="form-select" id="flag" name="flag" required>
                    {countries.map((c: CountryType) => <option value={c.id} key={c.id}>{c.name}</option>)}
                </select>
            </div>
            <div className="input-group mb-3">
                <span className="input-group-text">Порт приписки</span>
                <input type="text" className="form-control" id="id1" name="id1" required/>
            </div>
            <div className="input-group mb-3">
                <span className="input-group-text">Предназначение (перевозка пассажиров, грузов и др.)</span>
                <input type="text" className="form-control" id="id1" name="id1" required/>
            </div>
            <div className="input-group mb-3">
                <span className="input-group-text">Вид плавания (морское, внутреннее, смешанное (река-море))</span>
                <input type="text" className="form-control" id="id1" name="id1" required/>
            </div>
            <div className="input-group mb-3">
                <span className="input-group-text">Самоходное (несамоходное) судно</span>
                <input type="text" className="form-control" id="id1" name="id1" required/>
            </div>


            <hr/>
            <h5>3. Сведения об оснащённости техническими средствами обеспечения транспортной безопасности транспортного
                средства и сведения о пункте управления обеспечением транспортной безопасности</h5>
            TODO: таблица
            <hr/>
            <h5>4. Описание реализуемых мер по обеспечению транспортной безопасности транспортного средства,
                направленных на исполнение требований по обеспечению транспортной безопасности, учитывающих уровни
                безопасности для транспортных средств морского и внутреннего водного транспорта</h5>
            TODO: таблицы + рисунки
            <hr/>
            <h5>5. Сведения о лицах, назначенных субъектом транспортной инфраструктуры ответственными за обеспечение
                транспортной безопасности</h5>
            <b>Лицо, ответственное за обеспечение транспортной безопасности в субъекте транспортной инфраструктуры (ЛОТБ
                СТИ)</b>
            <div className="input-group mb-3">
                <span className="input-group-text">Фамилия, имя, отчество</span>
                <input type="text" className="form-control" id="id1" name="id1" required/>
            </div>
            <div className="input-group mb-3">
                <span className="input-group-text">Должность</span>
                <input type="text" className="form-control" id="id1" name="id1" required/>
            </div>
            <div className="input-group mb-3">
                <span className="input-group-text">Телефон мобильный</span>
                <input type="text" className="form-control" id="id1" name="id1" required/>
                <span className="input-group-text">Факс</span>
                <input type="text" className="form-control" id="id1" name="id1" required/>
                <span className="input-group-text">Адрес электронной почты</span>
                <input type="text" className="form-control" id="id1" name="id1" required/>
            </div>
            <div className="input-group mb-3">
                <span className="input-group-text">Номер и дата приказа о назначении</span>
                <input type="text" className="form-control" id="id1" name="id1" required/>
            </div>
            <br/>
            <b>Лицо, назначенное субъектом транспортной инфраструктуры ответственным за обеспечение транспортной
                безопасности транспортного средства (ЛОТБ ТС)</b>
            <div className="input-group mb-3">
                <span className="input-group-text">Фамилия, имя, отчество</span>
                <input type="text" className="form-control" id="id1" name="id1" required/>
            </div>
            <div className="input-group mb-3">
                <span className="input-group-text">Должность</span>
                <input type="text" className="form-control" id="id1" name="id1" required/>
            </div>
            <div className="input-group mb-3">
                <span className="input-group-text">Телефон мобильный</span>
                <input type="text" className="form-control" id="id1" name="id1" required/>
                <span className="input-group-text">Факс</span>
                <input type="text" className="form-control" id="id1" name="id1" required/>
                <span className="input-group-text">Адрес электронной почты</span>
                <input type="text" className="form-control" id="id1" name="id1" required/>
            </div>
            <div className="input-group mb-3">
                <span className="input-group-text">Номер и дата приказа о назначении</span>
                <input type="text" className="form-control" id="id1" name="id1" required/>
            </div>

        </section>
    )
}
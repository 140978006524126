import React, {ReactElement, useEffect, useState} from "react";
import {NewRequestModal} from "./modal/NewRequestModal";
import {REST} from "../../REST";
import {RequestType} from "../../types/RequestType";
import '../../css/status.css';
import {StatusList} from "./StatusList";
import {Loader} from "../../Loader";
import {RequestModal} from "./modal/RequestModal";

export function TabOutRequests(props: any): ReactElement {
    const [requests, setRequests] = useState<RequestType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showNewModal, setShowNewModal] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentRequest, setCurrentRequest] = useState<RequestType>();

    useEffect(() => {
        REST.getRequest("out").then((r: RequestType[]) => {
            setRequests(r);
            setLoading(false);
        });
    }, []);

    if (loading) {
        return (
            <div className="tab-pane fade show active" id="nav-out-requests" role="tabpanel"
                 aria-labelledby="nav-out-requests-tab" style={{backgroundColor: "white", padding: "10px", display: "flex", flexDirection: "column"}}>
                <div style={{display: "flex", flexDirection: "row", paddingBottom: "10px"}}>
                    <div className="Button Primary Disabled">Создать заявку</div>
                    <StatusList/>
                </div>

                <div className="list-group">
                    <Loader/>
                </div>
            </div>);
    }

    return (
        <div className="tab-pane fade show active" id="nav-out-requests" role="tabpanel"
             aria-labelledby="nav-out-requests-tab" style={{backgroundColor: "white", padding: "10px", display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", flexDirection: "row", paddingBottom: "10px"}}>
                <div className={"Button Primary"} onClick={() => setShowNewModal(true)}>Создать заявку</div>
                <StatusList/>
            </div>

            <div className="list-group">
                {requests.length === 0
                    ? <span className="list-group-item text-center">Нет исходящих заявок</span>
                    : requests.filter(r => r !== undefined).map((r: RequestType) =>
                        <div key={r.id} onClick={() => {setCurrentRequest(r); setShowModal(true);}}
                             className={'list-group-item list-group-item-action list-group-item-' + r.status.className}>
                            {'Заявка №' + r.id + ' от ' + r.formattedDate + ' на судно ' + r.otb}</div>
                    )}
            </div>

            <RequestModal visibleState={[showModal, setShowModal]} currentRequestState={[currentRequest, setCurrentRequest]} readOnly={true}/>
            <NewRequestModal visibleState={[showNewModal, setShowNewModal]} currentRequestState={[currentRequest, setCurrentRequest]} setRequests={setRequests}/>

        </div>
    );
}

import React, {ReactElement} from "react";
import {EmployeeType} from "./types/EmployeeType";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGears, faPlane, faShip, faTrainSubway, faTruck} from "@fortawesome/free-solid-svg-icons";
import {Utils} from "./utils/Utils";

export function EcosystemMenu(props: any): ReactElement {
    const me: EmployeeType = props.me;
    const [visible, setVisible] = props.visible;
    const [top, right, bottom, left] = props.position;

    if (!visible) {
        return (<></>);
    }

    return (
        <div style={{position: "absolute", zIndex: "998", top: top, left: "0", right: "0", bottom: "0"}}
             onClick={() => setVisible(false)}>
            <div className={"ProfileMenu"} style={{right: right, width: "250px"}} onClick={(e) => e.stopPropagation()}>

                <div className={"MenuItem" + (Utils.hasRole("road") ? "" : " disabled")} onClick={() => {
                    if (Utils.hasRole("road")) window.location.href = '/road'
                }}>
                    <FontAwesomeIcon icon={faTruck} style={{height: "18px", marginRight: "8px"}}/>
                    <span style={{marginLeft: "5px"}}>АВТОДОР</span>
                </div>

                <div className={"MenuItem" + (Utils.hasRole("water") ? "" : " disabled")} onClick={() => {
                    if (Utils.hasRole("water")) window.location.href = '/water'
                }}>
                    <FontAwesomeIcon icon={faShip} style={{height: "18px", marginRight: "8px"}}/>
                    <span style={{marginLeft: "5px"}}>МОРРЕЧФЛОТ</span>
                </div>

                <div className={"MenuItem" + (Utils.hasRole("rail") ? "" : " disabled")}>
                    <FontAwesomeIcon icon={faTrainSubway} style={{height: "18px", marginRight: "8px"}}/>
                    <span style={{marginLeft: "5px"}}>ЖЕЛДОР</span>
                </div>

                <div className={"MenuItem hidden" + (Utils.hasRole("air") ? "" : " disabled")}>
                    <FontAwesomeIcon icon={faPlane} style={{height: "18px", marginRight: "8px"}}/>
                    <span style={{marginLeft: "5px"}}>АВИАЦИЯ</span>
                </div>

                {Utils.hasRole("admin")
                    ? <div className={"MenuItem"} onClick={() => {
                        window.location.href = '/admin'
                    }}>
                        <FontAwesomeIcon icon={faGears} style={{height: "18px", marginRight: "8px"}}/>
                        <span style={{marginLeft: "5px"}}>Администрирование</span>
                    </div>
                    : ""
                }

            </div>
        </div>
    );
}

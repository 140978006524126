import React, {ReactElement, useEffect, useState} from "react";
import {Loader} from "../../Loader";
import {REST} from "../../REST";
import {NotificationType} from "../../types/NotificationType";
import {NotificationModal} from "./modal/NotificationModal";

export function TabOutNotifications(props: any): ReactElement {
    const [notifications, setNotifications] = useState<NotificationType[]>([]);
    const [currentNotification, setCurrentNotification] = useState<NotificationType>();
    const [loading, setLoading] = useState<boolean>(true);
    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        REST.getNotifications("out").then((n: NotificationType[]) => {
            setNotifications(n);
            setLoading(false);
        });
    }, []);

    if (loading) {
        return (<div className="tab-pane fade show active" id="nav-out-notifications" role="tabpanel"
                     aria-labelledby="nav-out-notifications-tab"
                     style={{backgroundColor: "white", padding: "10px", display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", flexDirection: "row", paddingBottom: "10px"}}>
                <div className={"Button Primary Disabled"}>Создать уведомление</div>
            </div>

            <div className="list-group">
                <Loader/>
            </div>
        </div>);
    }

    return (
        <div className="tab-pane fade show active" id="nav-out-notifications" role="tabpanel"
             aria-labelledby="nav-out-notifications-tab" style={{backgroundColor: "white", padding: "10px", display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", flexDirection: "row", paddingBottom: "10px"}}>
                <div className={"Button Primary"} onClick={() => setShowModal(true)}>Создать уведомление</div>
            </div>

            <div className="list-group">
                {notifications.length === 0
                    ? <span className="list-group-item text-center">Нет исходящих уведомлений</span>
                    : notifications.map((n: NotificationType) =>
                        <div className={'list-group-item list-group-item-action'} onClick={() => {
                            setCurrentNotification(n);
                            setShowModal(true);
                        }}>{'Уведомление №' + n.id}</div>
                    )}
            </div>

            <NotificationModal visibleState={[showModal, setShowModal]}
                                  notificationState={[currentNotification, setCurrentNotification]}/>
        </div>
    );
}
import React, {ReactElement} from 'react';
import {Main} from "./page/Main";
import {Navbar} from "./Navbar";
import {LoginPage} from "./LoginPage";
import {EmployeeType} from "./types/EmployeeType";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {RequestPage} from "./ecosystem/water/RequestPage";
import {PassportPage} from "./page/PassportPage";
import {NewContractPage} from "./page/contract/NewContractPage";
import {ContractPage} from "./page/contract/ContractPage";
import {ContractDKHAPage} from "./page/contract/ContractDKHAPage";
import {ProfilePage} from "./ProfilePage";
import {WaterIndex} from "./ecosystem/water/WaterIndex";
import {RoadIndex} from "./ecosystem/road/RoadIndex";
import {LegalPage} from "./ecosystem/LegalPage";
import {REST} from "./REST";
import {AdminIndex} from "./admin/AdminIndex";
import {PublicPage} from "./ecosystem/water/PublicPage";

export default function App(): ReactElement {
    const me: EmployeeType = JSON.parse(sessionStorage.getItem("me") ?? '{}');

    if (Object.keys(me).length === 0) {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/login" element={<LoginPage title={REST.title}/>}/>

                    <Route path="/water/public" element={<PublicPage title={REST.title}/>}/>
                    <Route path="/water/public/:id" element={<PublicPage title={REST.title}/>}/>

                    <Route path="*" element={<Navigate to={"/login"}/>}/>
                </Routes>
            </BrowserRouter>
        );
    }
    return (
        <>
            <Navbar title={REST.title} me={me}/>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Main me={me}/>}/>
                    <Route path="/profile" element={<ProfilePage me={me}/>}/>

                    <Route path="/water" element={<WaterIndex me={me}/>}/>
                    <Route path="/water/request/:id" element={<RequestPage me={me}/>}/>
                    <Route path="/water/passport" element={<PassportPage me={me}/>}/>
                    <Route path="/water/public" element={<PublicPage title={REST.title}/>}/>
                    <Route path="/water/public/:id" element={<PublicPage title={REST.title}/>}/>

                    <Route path="/road" element={<RoadIndex me={me}/>}/>

                    <Route path="/road/contract/new" element={<NewContractPage me={me}/>}/>
                    <Route path="/road/contract/:id" element={<ContractPage me={me}/>}/>
                    <Route path="/road/contract/:id/:dkha" element={<ContractDKHAPage me={me}/>}/>

                    <Route path="/admin" element={<AdminIndex me={me}/>}/>
                    <Route path="/legal" element={<LegalPage me={me}/>}/>

                    <Route path="*" element={<NotFound me={me}/>}/>
                </Routes>
            </BrowserRouter>
        </>
    );
}

export function NotFound(props: any): ReactElement {
    return (<><br/><div className="alert alert-danger text-center" role="alert">Страница не найдена</div></>)
}
/*
Routes:
<Route path="/logout" action={async () => forceUpdate()} />
 */
import React, {ReactElement} from "react";

export function Loader(props: any): ReactElement {
    const color = props.color ?? "rgb(129, 140, 153)";
    const size = props.size ?? "24px";
    const speed = props.speed ?? "1s";

    return (
        <div className={"Loader"}
             style={{
                 borderTopColor: color,
                 borderLeftColor: color,
                 borderRightColor: color,
                 width: size,
                 height: size,
                 marginLeft: "auto",
                 marginRight: "auto",
                 animation: "rotating " + speed + " linear infinite"
             }}></div>
    )
}
import React, {ChangeEventHandler, MouseEventHandler, ReactElement, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faPlus, faXmark} from "@fortawesome/free-solid-svg-icons";
import {REST} from "../../REST";
import {DKHAType} from "../../types/DKHAType";

export function ObjectTr(props: any): ReactElement {
    //let {district} = props;
    const [objects, setObjects] = useState<DKHAType[]>([]);
    const [needReplace, setNeedReplace] = useState<boolean>(false);
    const [canAdd, setCanAdd] = useState<boolean>(false);

    const objectDHA = useRef<HTMLInputElement>(null);
    const objectName = useRef<HTMLInputElement>(null);
    const objectLength = useRef<HTMLInputElement>(null);
    const objectCategory = useRef<HTMLSelectElement>(null);

    let addObject: MouseEventHandler<HTMLButtonElement> = (event) => {
        let newObject: DKHAType = {
            name: objectName.current!.value,
            length: parseFloat(objectLength.current!.value),
            category: parseInt(objectCategory.current!.value),
            number: parseInt(objectDHA.current!.value)
        }
        objectName.current!.value = '';
        objectLength.current!.value = '0';
        objectCategory.current!.item(0)!.selected = true;
        objectDHA.current!.value = '';
        setObjects((prev) => [...prev, newObject]);
        setCanAdd(false);
    }

    const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        if(event.target.name === 'dha' && event.target.value.length === 7) {
            REST.getDKHAByNumber(event.target.valueAsNumber).then(d => {
                objectName.current!.value = d?.name ?? '';
                objectLength.current!.value = (d?.length ?? 0.0).toString();
                objectCategory.current!.item((d?.category ?? 4) - 1)!.selected = true;
                setCanAdd(d?.number !== undefined);
            })
        }
        if (objectName.current!.value === undefined
            || objectLength.current!.value === undefined
            || objectCategory.current!.value === undefined
            || objectDHA.current!.value === undefined

            || objectName.current!.value === ''
            || objectLength.current!.value === ''
            || objectCategory.current!.value === ''
            || objectDHA.current!.value === '') {
            setCanAdd(false);
        } else {
            setCanAdd(true);
        }
    }

    let removeMe = (dha: number) => {
        setObjects(objects.filter(o => o.number !== dha));
    }

    return (<>
        {objects.map((o, index) =>
            <tr key={o.number}>
                <td>
                    <div className={"btn-group btn-group-sm"}>
                        <button className="btn btn-danger" type="button" onClick={() => removeMe(o.number)}>
                            <FontAwesomeIcon icon={faXmark}/></button>
                    </div>
                </td>
                <td className={"text-center"}>{o.number.toString().padStart(7, '0')}</td>
                <td className={"text-center"}>{o.name}</td>
                <td className={"text-center"}>{o.length}</td>
                <td className={"text-center"}>{o.category}</td>
            </tr>
        )}
        <tr>
            {needReplace
                ? <>
                    <td>
                        <div className={"btn-group btn-group-sm"}>
                            <button className={"btn btn-success" + (canAdd ? "" : " disabled")} type="button"
                                    onClick={addObject}>
                                <FontAwesomeIcon icon={faCheck}/></button>
                            <button className="btn btn-danger" type="button" onClick={() => setNeedReplace(false)}>
                                <FontAwesomeIcon icon={faXmark}/></button>
                        </div>
                    </td>
                    <td>
                        <input type="number" className="form-control" name={"dha"} id={"objectDHA"} min={0}
                               step={1} ref={objectDHA} onChange={handleInputChange} placeholder={"0123456"}/>
                    </td>
                    <td>
                        <input type="text" className="form-control" name={"object"} id={"objectName"}
                               ref={objectName} onChange={handleInputChange} placeholder={"Мост через реку"}/>
                    </td>
                    <td>
                        <input type="number" className="form-control" name={"length"} id={"objectLength"}
                               min={0.00} step={0.01} ref={objectLength} onChange={handleInputChange} placeholder={"10.00"}/>
                    </td>
                    <td>
                        <select className="form-control" name={"category"} id={"objectCategory"} ref={objectCategory} defaultValue={1}>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                        </select>
                    </td>
                </>
                : <td colSpan={5}>
                    <button className={"btn btn-success btn-sm"} style={{width: "100%"}} type={"button"}
                            id={"addObjectBtn"} onClick={(e) => setNeedReplace(true)}><FontAwesomeIcon
                        icon={faPlus}/>&nbsp;Добавить объект
                    </button>
                </td>
            }
        </tr>
    </>)
}

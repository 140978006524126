import {ReactElement} from "react";
import {StatusEnum} from "../../types/StatusEnum";

export function StatusPart(props: any): ReactElement {
    const status: StatusEnum = props.status;
    if(status.value === "NEW")
        return (<span className={"badge bg-" + status.className + " text-dark"} style={{border: "1px solid darkgrey", height: "18px", fontWeight: "400"}}>Новая</span>);
    else if(status.value === "REVIEW")
        return (<span className={"badge"} style={{height: "18px", backgroundColor: "var(--otb-primary)", fontWeight: "400"}}>На рассмотрении</span>);
    else if(status.value === "PROCESS")
        return (<span className={"badge bg-" + status.className} style={{height: "18px", color: "black", fontWeight: "400"}}>В работе</span>);
    else if(status.value === "EXECUTED")
        return (<span className={"badge bg-" + status.className} style={{height: "18px", fontWeight: "400"}}>Исполнена</span>);
    else if(status.value === "REJECTED")
        return (<span className={"badge bg-" + status.className} style={{height: "18px", fontWeight: "400"}}>Отклонена</span>);

    return (<span className={"badge bg-" + status.className} style={{border: "1px solid darkgrey", height: "18px", fontWeight: "400"}}>{status.value}</span>);
}

import React, {ReactElement, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {REST} from "../../REST";
import {RequestType} from "../../types/RequestType";
import {StatusPart} from "../../component/part/StatusPart";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faDownload, faEnvelope, faFile, faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {Loader} from "../../Loader";
import {FileType} from "../../types/FileType";

export function RequestPage(props: any): ReactElement {
    const {id} = useParams<string>();
    const [request, setRequest] = useState<RequestType>();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        REST.getRequestById(id!).then(r => {
            setRequest(r);
            setLoading(false);
        });
    }, [id]);

    if (loading)
        return (<div className={'Page'}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "16px",
                borderRadius: "12px",
                backgroundColor: "rgb(255, 255, 255)",
                padding: "8px 12px 8px 12px",
                borderBottom: "1px solid var(--otb-background)",
                minWidth: "60%"
            }}>
                <Loader/>
            </div>
        </div>);

    return (<div className={'Page'}>
            <div className={"Card"} style={{paddingTop: 0, paddingLeft: 0, paddingRight: 0, minWidth: "50%"}}>
                <div className={"Header"}
                     style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                    <div className={"Button"} onClick={() => window.location.href = '/'}><FontAwesomeIcon
                        icon={faArrowLeft} style={{paddingRight: "4px", width: "24px"}}/><span
                        style={{fontSize: "14px"}}>Назад</span>
                    </div>

                    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <div className="LoginButton" style={{
                            height: "27px",
                            margin: "0 0 0 0",
                            maxWidth: "120px",
                            textAlign: "center",
                            lineHeight: "27px",
                        }} onClick={() => REST.downloadReport(request!, 'docx')}><FontAwesomeIcon
                            icon={faDownload}/>&nbsp;Скачать
                        </div>

                        <span style={{fontSize: "16px", paddingLeft: "8px", paddingRight: "8px"}}>{"Заявка №" + request!.id + " от " + request!.formattedDate + " "}</span>

                        <StatusPart status={request!.status}/>
                    </div>

                    <div className="Button" onClick={() => {
                        alert('Будет произведена отправка в ЕГИС')
                    }}><span style={{fontSize: "14px"}}>Отправить в ЕГИС ОТБ</span><FontAwesomeIcon icon={faEnvelope} style={{paddingLeft: "4px", width: "24px"}}/>
                    </div>
                </div>

                <section style={{width: "100%", padding: "12px 12px 8px 12px"}}>
                    <div>
                        <div className="input-group input-group-sm mb-1">
                            <span className="input-group-text">Заказчик</span>
                            <div className="form-control" id="customername">{request!.customer.name}</div>
                            <span className="input-group-text">Наименование судна</span>
                            <div className="form-control" id="otbname">{request!.otb}</div>
                            <span className="input-group-text">Флаг</span>
                            <div className="form-control" id="flag">{request!.flag.name}</div>
                        </div>
                        <div className="input-group input-group-sm mb-1">
                            <span className="input-group-text">ИМО номер</span>
                            <div className="form-control" id="imonumber">{request!.imonumber.toString()}</div>
                            <span className="input-group-text">Регистровый номер</span>
                            <div className="form-control" id="registrynumber">{request!.registrynumber}</div>
                            <span className="input-group-text">Реестровый номер</span>
                            <div className="form-control" id="reestrnumber">{request!.reestrnumber}</div>
                        </div>
                        <div className="input-group input-group-sm mb-1">
                            <span className="input-group-text">Контакты судна</span>
                            <div className="form-control" id="contact">{request!.contact}</div>
                        </div>
                        <div className="input-group input-group-sm mb-1">
                            <span className="input-group-text">Приход в порт (причал)</span>
                            <div className="form-control" id="inport">{request!.inport}</div>
                            <span className="input-group-text">страна</span>
                            <div className="form-control" id="incountry">{request!.incountry.name}</div>
                            <span className="input-group-text">из порта</span>
                            <div className="form-control" id="fromport">{request!.fromport}</div>
                            <span className="input-group-text">страна</span>
                            <div className="form-control" id="fromcountry">{request!.fromcountry.name}</div>
                        </div>
                        <div className="input-group input-group-sm mb-1">
                            <span className="input-group-text">Отход в порт</span>
                            <div className="form-control" id="outport">{request!.outport}</div>
                            <span className="input-group-text">страна</span>
                            <div className="form-control" id="outcountry">{request!.outcountry.name}</div>
                        </div>
                        <div className="input-group input-group-sm mb-1">
                            <span className="input-group-text">Цель захода</span>
                            <div className="form-control" id="target">{request!.target}</div>
                            <span className="input-group-text">место</span>
                            <div className="form-control" id="place">{request!.place}</div>
                        </div>
                        <div className="input-group input-group-sm mb-1">
                            <span className="input-group-text">Груз</span>
                            <div className="form-control" id="cargo">{request!.cargo}</div>
                            <span className="input-group-text">кол-во (т./куб.м)</span>
                            <div className="form-control" id="count">{request!.count}</div>
                        </div>
                        <div className="input-group input-group-sm mb-1">
                            <span className="input-group-text">Ориентировочная дата и время прибытия</span>
                            <div className="form-control" id="indatetime">{request!.formattedIndatetime}</div>
                        </div>
                        <div className="input-group input-group-sm mb-1">
                            <span className="input-group-text">Ориентировочная дата начала и окончания погрузки</span>
                            <div className="form-control" id="startloadtime">{request!.formattedStartloadtime}</div>
                            <span className="input-group-text">&nbsp;-&nbsp;</span>
                            <div className="form-control" id="endloadtime">{request!.formattedEndloadtime}</div>
                        </div>
                        <div className="input-group input-group-sm mb-1">
                            <span className="input-group-text">Адрес места погрузки</span>
                            <div className="form-control" id="addressobject">{request!.addressobject}</div>
                            <span className="input-group-text">регион</span>
                            <div className="form-control" id="addressobject">{request!.addressobjectregion.name}</div>
                        </div>
                        <div className="input-group input-group-sm mb-1">
                            <span className="input-group-text">Наименование оператора</span>
                            <div className="form-control" id="nameoperator">{request!.nameoperator}</div>
                            <span className="input-group-text">Контакты оператора</span>
                            <div className="form-control" id="contactoperator">{request!.contactoperator}</div>
                        </div>
                        <div className="input-group input-group-sm mb-1">
                            <span className="input-group-text">Судовладелец</span>
                            <div className="form-control" id="mastername">{request!.mastername}</div>
                        </div>
                        <div className="input-group input-group-sm mb-1">
                            <span className="input-group-text">Контакты судовладельца</span>
                            <div className="form-control" id="contactmaster">{request!.contactmaster}</div>
                        </div>
                        <div className="input-group input-group-sm mb-1">
                            <span className="input-group-text">Наименование грузоотправителя</span>
                            <div className="form-control" id="namecargo">{request!.namecargo}</div>
                        </div>
                        <div className="input-group input-group-sm mb-1">
                            <span className="input-group-text">Контакты грузоотправителя</span>
                            <div className="form-control" id="namecargo">{request!.contactcargo}</div>
                        </div>
                        <div className="input-group input-group-sm mb-1">
                            <span className="input-group-text">Наименование грузополучателя</span>
                            <div className="form-control" id="nameconsignee">{request!.nameconsignee}</div>
                        </div>
                        <div className="input-group input-group-sm mb-1 mt-3">
                            <span className="input-group-text">Назначена</span>
                            <div className="form-control" id="namecargo">{request!.finalExecutor.fio}</div>
                        </div>
                    </div>
                </section>


            </div>
        </div>
    );
}

/*

    <script>
        /*function loadRequest(rid) {
        if(r.status === "NEW") {
        btnGroup = reviewHtml + rejectHtml;
    } else if(r.status === "REVIEW") {
        btnGroup = processHtml + rejectHtml;
    } else if(r.status === "PROCESS") {
        btnGroup = executeHtml + rejectHtml;
    }
        $('.modal-header').removeClass().addClass('modal-header bg-' + ((r.status === "NEW") ? 'light': (r.status === "REVIEW")? 'primary text-light' : (r.status === "PROCESS") ? 'warning' : (r.status === "EXECUTED") ? 'success text-light': 'danger text-light'));
        $('div.modal-footer div.statuses').html(btnGroup);
    }
        });
        }*//*

    function download(t, format) {
        const uid = 1;
        let req = $('#viewRequestModalLabel').attr("rid");
        window.open("/report/download." + format + "?uid=" + uid + "&req={{request.id}}");
    }

    function downloadFile(fid) {
        window.open("/api/file/download?fid=" + fid);
    }
</script>
 */
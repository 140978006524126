import React, {ChangeEventHandler, ReactElement, useRef} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {REST} from "../REST";
import {CompanyType} from "../types/CompanyType";

export function RegisterCompanyModal(props: any): ReactElement {
    const [visible, setVisible] = props.visibleState;
    const [currentCompany, setCurrentCompany] = props.companyState as [CompanyType | null, React.Dispatch<React.SetStateAction<CompanyType | null>>];

    const inn = useRef<HTMLInputElement>(null);
    const ogrn = useRef<HTMLInputElement>(null);
    const kpp = useRef<HTMLInputElement>(null);
    const name = useRef<HTMLInputElement>(null);
    const director = useRef<HTMLInputElement>(null);
    const fullName = useRef<HTMLInputElement>(null);
    const address = useRef<HTMLInputElement>(null);

    const hideMe = () => {
        setCurrentCompany(null);
        setVisible(false);
    }

    if (!visible) {
        return (<></>);
    }

    const handleINNChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        if (event.target.value.length === 10 || event.target.value.length === 12) {
            REST.getCompanyByInn(parseInt(event.target.value), false).then(c => {
                inn.current!.valueAsNumber = c.inn;
                ogrn.current!.valueAsNumber = c.ogrn;
                address.current!.value = c.address;
                name.current!.value = c.name;
                director.current!.value = c.director;
                fullName.current!.value = c.fullName;
                kpp.current!.valueAsNumber = c.kpp;
            })
        }
    }

    const registerCompany = () => {
        let company: CompanyType = {
            inn: inn.current!.valueAsNumber,
            address: address.current!.value,
            director: director.current!.value,
            name: name.current!.value,
            k: inn.current!.valueAsNumber < 9999999999 ? 'ul' : 'ip',
            fullName: fullName.current!.value,
            ogrn: ogrn.current!.valueAsNumber,
            kpp: kpp.current!.valueAsNumber,
            is_closed: null
        }
        REST.registerCompany(company).then((c: CompanyType) => {
            hideMe();
        });
    }

    return (
        <div className={"ModalBackground"} onClick={() => hideMe()}>
            <div className={"Modal"} onClick={(e) => e.stopPropagation()}>
                <div className={"ModalHeader"} style={{justifyContent: "space-between"}}>

                    {currentCompany !== null
                        ? <>{(currentCompany as
                            CompanyType).name} {(currentCompany as CompanyType).is_closed ?
                            <b style={{color: "#ff3347"}}>Ликвидировано</b> : ""}</>
                        : "Регистрация организации"}

                    <div onClick={() => hideMe()} style={{cursor: "pointer"}}>
                        <FontAwesomeIcon icon={faXmark}/>
                    </div>
                </div>

                <div className={"ModalBody"}>


                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">ИНН</span>
                        {currentCompany !== null
                            ? <div className="form-control" id="inn">{(currentCompany as CompanyType).inn}</div>
                            :
                            <input type="number" className="form-control" id="inn" name="inn" placeholder={"123456789"}
                                   required onChange={handleINNChange} ref={inn}/>
                        }
                        <span className="input-group-text">ОГРН</span>
                        {currentCompany !== null
                            ? <div className="form-control" id="ogrn">{(currentCompany as CompanyType).ogrn}</div>
                            : <input type="number" className="form-control" id="ogrn" name="ogrn" required ref={ogrn}/>
                        }
                        <span className="input-group-text">КПП</span>
                        {currentCompany !== null
                            ? <div className="form-control" id="kpp">{(currentCompany as CompanyType).kpp}</div>
                            : <input type="number" className="form-control" id="kpp" name="kpp" required ref={kpp}/>
                        }
                    </div>

                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Наименование</span>
                        {currentCompany !== null
                            ? <div className="form-control" id="name">{(currentCompany as CompanyType).name}</div>
                            : <input type="text" className="form-control" id="name" name="name" required ref={name}/>
                        }
                        <span className="input-group-text">Директор</span>
                        {currentCompany !== null
                            ?
                            <div className="form-control" id="director">{(currentCompany as CompanyType).director}</div>
                            : <input type="text" className="form-control" id="director" name="director" required
                                     ref={director}/>
                        }
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Полное наименование</span>
                        {currentCompany !== null
                            ?
                            <div className="form-control" id="fullName">{(currentCompany as CompanyType).fullName}</div>
                            : <input type="text" className="form-control" id="fullName" name="fullName" required
                                     ref={fullName}/>
                        }
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Адрес</span>
                        {currentCompany !== null
                            ? <div className="form-control" id="address">{(currentCompany as CompanyType).address}</div>
                            : <input type="text" className="form-control" id="address" name="address" required
                                     ref={address}/>
                        }
                    </div>


                </div>

                <div className={"ModalFooter"}>
                    {currentCompany !== null
                        ? (currentCompany as CompanyType).is_closed
                            ? ""
                            : <div className="Button Danger" onClick={() => {
                                alert('TODO: block company')
                            }}>Ликвидировать</div>
                        : <div className="Button Primary" onClick={() => {
                            registerCompany()
                        }}>Зарегистрировать</div>
                    }

                    <div className="Button" style={{marginLeft: "10px"}}
                         onClick={() => hideMe()}>Отмена
                    </div>
                </div>

            </div>
        </div>
    );
}
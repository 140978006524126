import React, {ReactElement} from "react";
import {REST} from "./REST";
import {faArrowRightFromBracket, faCircleQuestion, faFileShield} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AccountBlock} from "./AccountBlock";
import {EmployeeType} from "./types/EmployeeType";

export function ProfileMenu(props: any): ReactElement {
    const me: EmployeeType = props.me;
    const [visible, setVisible] = props.visible;

    if (!visible) {
        return (<></>);
    }

    return (
        <div style={{position: "absolute", zIndex: "998", top: "48px", left: "0", right: "0", bottom: "0"}}
             onClick={() => setVisible(false)}>
            <div className={"ProfileMenu"} onClick={(e) => e.stopPropagation()}>

                <AccountBlock user={me}/>

                {me.login === me.company.inn.toString()
                    ? <div className={"MenuItem"} onClick={() => {
                        window.location.href = "/legal";
                    }}>
                        <FontAwesomeIcon
                            icon={faFileShield} style={{height: "18px", marginRight: "8px"}}/>
                        <span style={{marginLeft: "5px"}}>Правовая информация</span>
                    </div>
                    : ""
                }

                <div className={"MenuItem"} onClick={() => {
                    REST.logout();
                }}>
                    <FontAwesomeIcon
                        icon={faArrowRightFromBracket} style={{height: "18px", marginRight: "8px"}}/>
                    <span style={{marginLeft: "5px"}}>Выход</span>
                </div>

            </div>
        </div>
    );
}

/*
<div className={"MenuItem"}>
                    <FontAwesomeIcon
                        icon={faCircleQuestion} style={{height: "18px", marginRight: "8px"}}/>
                    <span style={{marginLeft: "5px"}}>Помощь</span>
                </div>
 */
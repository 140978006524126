import React, {ReactElement, useState} from "react";
import {EmployeeType} from "../../types/EmployeeType";
import {Utils} from "../../utils/Utils";
import {TabInContract} from "./TabInContract";
import {TabOutContract} from "./TabOutContract";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRightFromBracket, faArrowRightToBracket} from "@fortawesome/free-solid-svg-icons";

export function RoadIndex(props: any): ReactElement {
    const me: EmployeeType = props.me;

    const [activeTab, setActiveTab] = useState<number>(Utils.hasRole("road_out_contract") ? 0 : (Utils.hasRole("road_in_contract") ? 1 : 0));

    return (
        <div className={'main-page'}>
            <div style={{
                display: "flex",
                marginTop: "16px",
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
                backgroundColor: "rgb(255, 255, 255)",
                height: "44px",
                padding: "8px 12px 8px 12px",
                borderBottom: "1px solid var(--otb-background)"
            }}>

                {Utils.hasRole("road_out_contract")
                    ? <div className={"NavLink" + (activeTab === 0 ? " Active" : "")} onClick={() => setActiveTab(0)}>
                        <FontAwesomeIcon icon={faArrowRightFromBracket}
                                         style={{height: "16px", width: "16px", paddingRight: "6px"}}/>
                        Исходящие контракты
                    </div>
                    : ""
                }

                {Utils.hasRole('road_in_contract')
                    ? <div className={"NavLink" + (activeTab === 1 ? " Active" : "")} onClick={() => setActiveTab(1)}>
                        <FontAwesomeIcon icon={faArrowRightToBracket}
                                         style={{height: "16px", width: "16px", paddingRight: "6px"}}/>
                        Входящие контракты
                    </div>
                    : ""
                }

            </div>

            <div className="tab-content">

                {Utils.hasRole("road_out_contract") && activeTab === 0 ? <TabOutContract active={true} me={me}/> : ""}
                {Utils.hasRole("road_in_contract") && activeTab === 1 ? <TabInContract active={true} me={me}/> : ""}

            </div>
        </div>
    )
}
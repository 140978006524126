import {ReactElement} from "react";
import {EmployeeType} from "./types/EmployeeType";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {Utils} from "./utils/Utils";

export function AccountBlock(props: any): ReactElement {
    const user: EmployeeType = props.user;

    return (
        <div className={"AccountBlock"} onClick={() => window.location.href="/profile"}>
            <div style={{padding: "10px 10px 10px 16px", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <img src={"/userIcon.svg"}
                     alt={user.login}
                     style={{marginRight: "12px", width: "40px", height: "40px", borderRadius: "50%"}}/>
                <div style={{marginRight: "8px", display: "flex", flexDirection: "column"}}>
                    <span>{Utils.makeFIO(user.fio)}</span>
                    {user.cert
                        ? <span style={{color: "rgb(129, 140, 153)"}}>{user.cert}</span>
                        : ""
                    }
                    <span style={{color: "rgb(129, 140, 153)"}}>{user.company.name}</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </div>

        </div>
    )
}
import React, {EventHandler, FocusEventHandler, ReactElement, useEffect, useState} from "react";
import {REST} from "./REST";
import {Loader} from "./Loader";
import {EmployeeType} from "./types/EmployeeType";

export function ProfilePage(props: any): ReactElement {
    const [loading, setLoading] = useState<boolean>(true);
    const [me, setMe] = useState<EmployeeType>();

    useEffect(() => {
        REST.getMe().then(m => {
            setMe(m);
            setLoading(false);
        });
    }, []);

    const onChangePassword: FocusEventHandler<HTMLInputElement> = (e) => {
        REST.changePassword(e.target.value);
    }

    if (loading)
        return (<div className={'main-page'} style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
            <div className={"Card"}>
            <Loader/>
            </div>
        </div>);

    return (<div className={'Page'}>



        {me?.login === me?.company.inn.toString()
            ? <div className={"Card"} style={{paddingTop: 0, paddingLeft: 0, paddingRight: 0}}>
                <div className={"Header"}>Информация об организации</div>

                <div className="card-body">
                    <div className={"row g-2"}>
                        <div className="form-floating col-md">
                            <div className="form-control form-control-sm" id="companyINN">{me?.company.inn}</div>
                            <label htmlFor="companyINN">ИНН</label>
                        </div>
                        <div className="form-floating col-md">
                            <div className="form-control form-control-sm"
                                 id="companyK">{me?.company.k === "ul" ? "Юридическое лицо" : me?.company.k}</div>
                            <label htmlFor="companyK">Правовой статус</label>
                        </div>
                    </div>
                    <div className="form-floating mt-1">
                        <div className="form-control form-control-sm" id="companyName">{me?.company.name}</div>
                        <label htmlFor="companyName">Краткое наименование</label>
                    </div>
                    <div className="form-floating mt-1 mb-1">
                        <div className="form-control form-control-sm" id="companyFullName">{me?.company.fullName}</div>
                        <label htmlFor="companyFullName">Полное наименование</label>
                    </div>
                    <div className={"row g-2"}>
                        <div className="form-floating col-md">
                            <div className="form-control form-control-sm" id="companyOGRN">{me?.company.ogrn}</div>
                            <label htmlFor="companyOGRN">ОГРН</label>
                        </div>
                        <div className="form-floating col-md">
                            <div className="form-control form-control-sm" id="companyKPP">{me?.company.kpp}</div>
                            <label htmlFor="companyKPP">КПП</label>
                        </div>
                    </div>
                    <div className="form-floating mt-1">
                        <div className="form-control form-control-sm"
                             id="companyDirector">{me?.company.director}</div>
                        <label htmlFor="companyDirector">Руководитель</label>
                    </div>
                    <div className="form-floating mt-1">
                        <div className="form-control form-control-sm" id="companyAddress" style={{overflowWrap: "break-word"}}>{me?.company.address}</div>
                        <label htmlFor="companyAddress">Юридический адрес</label>
                    </div>
                </div>
            </div>
            : ""}
        <div className={"Card"} style={{paddingTop: 0, paddingLeft: 0, paddingRight: 0}}>
            <div className={"Header"}>Информация о пользователе</div>

            <div className="card-body">
                <div className={"row g-2"}>
                    <div className="form-floating col-md">
                        <div className="form-control form-control-sm" id="userFIO">{me?.fio}</div>
                        <label htmlFor="userFIO">ФИО</label>
                    </div>
                    <div className="form-floating col-md">
                        <div className="form-control form-control-sm" id="userCert">{me?.cert?? "-"}</div>
                        <label htmlFor="userCert">Свидетельство</label>
                    </div>
                </div>
                <div className="form-floating mt-1 mb-1">
                    <div className="form-control form-control-sm" id="userRoles" style={{overflowWrap: "break-word"}}>
                        {me?.roles.map(r=> r.name).join(", ")}
                    </div>
                    <label htmlFor="userRoles">Конфигурация доступа</label>
                </div>
                <div className={"row g-2"}>
                    <div className="form-floating col-md">
                        <div className="form-control form-control-sm" id="userLogin">{me?.login}</div>
                        <label htmlFor="userLogin">Логин</label>
                    </div>
                    <div className="form-floating col-md">
                        <input className="form-control form-control-sm" id="userPassword" onBlur={onChangePassword}/>
                        <label htmlFor="userLogin">Новый пароль</label>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}
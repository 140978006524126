import React, {ReactElement, useRef, useState} from "react";
import {EmployeeType} from "./types/EmployeeType";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faTriangleExclamation, faChevronDown} from "@fortawesome/free-solid-svg-icons";
import './css/otb.css';
import {Utils} from "./utils/Utils";
import {ProfileMenu} from "./ProfileMenu";
import {EcosystemMenu} from "./EcosystemMenu";

export function Navbar(props: any): ReactElement {
    const me: EmployeeType = props.me;
    const title: string = props.title;

    const [showProfileMenu, setShowProfileMenu] = useState<boolean>(false);
    const [showEcosystemMenu, setShowEcosystemMenu] = useState<boolean>(false);
    const navMenu = useRef<HTMLDivElement>(null);

    return (<>
        <nav>
            <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                <a href={"/"} style={{textDecoration: "none", color: "var(--otb-text-primary)", marginRight: "15px"}}>
                    <h5 style={{margin: "0"}}>{title}</h5>
                </a>

                <a href={"/"} style={{textDecoration: "none", color: "var(--otb-text-primary)", marginRight: "15px"}}>
                    <b>{me.company.name}</b> (ИНН: {me.company.inn})
                </a>
                {me.cert !== null
                    ? ""
                    : ""
                }
            </div>

            <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                <div className={"NavMenu"} ref={navMenu} onClick={(e) => {
                    setShowEcosystemMenu(!showEcosystemMenu)
                }}><FontAwesomeIcon icon={faBars}/></div>

                <div className={"NavProfile"} onClick={(e) => {
                    setShowProfileMenu(!showProfileMenu)
                }}>
                    <img
                        src={"/userIcon.svg"}
                        alt={me.login} style={{borderRadius: "50%"}}/>
                    {Utils.makeFIO(me.fio)}
                    <FontAwesomeIcon icon={faChevronDown} style={{height: "14px"}}/>
                </div>
            </div>
        </nav>
        <ProfileMenu visible={[showProfileMenu, setShowProfileMenu]} me={me}/>
        <EcosystemMenu visible={[showEcosystemMenu, setShowEcosystemMenu]} me={me}
                       position={["48px", window.innerWidth - (navMenu.current?.offsetLeft ?? 0) - (navMenu.current?.clientWidth ?? 0) + "px", "0px", "0px"]}/>
    </>);
}
/*
<a href={"/"} style={{textDecoration: "none", color: "var(--otb-text-primary)", marginRight: "15px"}}>
    <img src={'/tab.svg'} style={{height: "24px"}} alt={title}/>
</a>
 */
import React, {ReactElement, useEffect, useState} from "react";
import {REST} from "../../REST";
import {RequestType} from "../../types/RequestType";
import {StatusList} from "./StatusList";
import {Loader} from "../../Loader";
import {RequestModal} from "./modal/RequestModal";
import {EmployeeType} from "../../types/EmployeeType";

export function TabInRequests(props: any): ReactElement {
    const me = props.me as EmployeeType;
    const active = props.active;
    const [requests, setRequests] = useState<RequestType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentRequest, setCurrentRequest] = useState<RequestType>();

    useEffect(() => {
        REST.getRequest("in").then((r: RequestType[]) => {
            setRequests(r);
            setLoading(false);
        });
    }, [loading]);

    if (loading) {
        return (<div className={"tab-pane fade show active"} id="nav-in-requests" role="tabpanel"
                     aria-labelledby="nav-in-requests-tab" style={{backgroundColor: "white"}}>
            <div className="dropdown" style={{paddingTop: "10px", paddingBottom: "10px", display: "flex"}}>
                <StatusList/>
            </div>
            <table className="table table-hover table-bordered">
                <thead>
                <tr>
                    <th>№, дата</th>
                    <th>Судно</th>
                    <th>Приход в порт (причал)</th>
                    <th>Отход в порт</th>
                    <th>Цель захода / место</th>
                    <th>Груз / кол-во (т./куб.м)</th>
                    <th>Ориентировочная дата и время прибытия</th>
                    <th>Ориентировочная дата погрузки</th>
                    <th>Контакты судна</th>
                    <th>Контакты оператора</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td colSpan={10} style={{textAlign: "center"}}><Loader/></td>
                </tr>
                </tbody>
            </table>
        </div>);
    }

    return (
        <div className={"tab-pane fade show active"} id="nav-in-requests" role="tabpanel"
             aria-labelledby="nav-in-requests-tab" style={{backgroundColor: "white"}}>
            <div className="dropdown" style={{paddingTop: "10px", paddingBottom: "10px", display: "flex"}}>
            <StatusList/>
            </div>
            <table className="table table-hover table-bordered">
                <thead>
                <tr>
                    <th>№, дата</th>
                    <th>Судно</th>
                    <th>Приход в порт (причал)</th>
                    <th>Отход в порт</th>
                    <th>Цель захода / место</th>
                    <th>Груз / кол-во (т./куб.м)</th>
                    <th>Ориентировочная дата и время прибытия</th>
                    <th>Ориентировочная дата погрузки</th>
                    <th>Контакты судна</th>
                    <th>Контакты оператора</th>
                </tr>
                </thead>
                <tbody>
                {requests.length === 0
                    ? <tr><td colSpan={10} style={{textAlign: "center"}}>Нет входящих заявок</td></tr>
                : requests.map((r: RequestType) =>
                    <tr className={'table-' + r.status.className}
                        onClick={() => {
                            setCurrentRequest(r);
                            setShowModal(true);
                        }}
                        id={'rid-' + r.id} key={r.id} style={{cursor: "pointer"}}>
                        <td>{'№' + r.id + ' от ' + r.formattedDate}</td>
                        <td>{r.otb}</td>
                        <td>{r.inport}</td>
                        <td>{r.outport}</td>
                        <td>{r.target} / {r.place}</td>
                        <td>{r.cargo} / {r.count}</td>
                        <td>{r.formattedIndatetime}</td>
                        <td>{r.formattedStartloadtime} - {r.formattedEndloadtime}</td>
                        <td>{r.contact}</td>
                        <td>{r.contactoperator}</td>
                    </tr>
                )}
                </tbody>
            </table>

            <RequestModal visibleState={[showModal, setShowModal]} currentRequestState={[currentRequest, setCurrentRequest]} me={me}/>
        </div>
    );
}

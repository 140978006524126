import React, {ReactElement, useEffect, useState} from "react";
import {Loader} from "../Loader";
import {DogovorType} from "../types/DogovorType";
import {REST} from "../REST";
import {AddContractModal} from "./AddContractModal";

export function TabAdminContract(props: any): ReactElement {
    const [contracts, setContracts] = useState<DogovorType[]>([])
    const [loading, setLoading] = useState<boolean>(true);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentContract, setCurrentContract] = useState<DogovorType | null>(null);

    useEffect(() => {
        REST.getDogovors().then((d: DogovorType[]) => {
            setContracts(d)
            setLoading(false);
        });
    }, []);

    if (loading) {
        return (<div className="tab-pane fade show active" id="nav-admin-contract" role="tabpanel"
                     aria-labelledby="nav-admin-contract-tab"
                     style={{backgroundColor: "white", padding: "10px", display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", flexDirection: "row", paddingBottom: "10px"}}>
                <div className={"Button Primary Disabled"}>Добавить договор</div>
            </div>
            <table className="table table-hover table-bordered">
                <thead>
                <tr>
                    <th>№ договора</th>
                    <th>Дата начала</th>
                    <th>Дата окончания</th>
                    <th>Заказчик</th>
                    <th>Исполнитель</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td colSpan={5} style={{textAlign: "center"}}><Loader/></td>
                </tr>
                </tbody>
            </table>
        </div>);
    }

    return (<div className="tab-pane fade show active" id="nav-admin-contract" role="tabpanel"
                 aria-labelledby="nav-admin-contract-tab"
                 style={{backgroundColor: "white", padding: "10px", display: "flex", flexDirection: "column"}}>
        <div style={{display: "flex", flexDirection: "row", paddingBottom: "10px"}}>
            <div className={"Button Primary"} onClick={() => setShowModal(true)}>Добавить договор</div>
        </div>
        <table className="table table-hover table-bordered">
            <thead>
            <tr>
                <th>№ договора</th>
                <th>Дата начала</th>
                <th>Дата окончания</th>
                <th>Заказчик</th>
                <th>Исполнитель</th>
            </tr>
            </thead>
            <tbody>
            {contracts.length === 0
                ? <tr>
                    <td colSpan={5}>Нет заключенных договоров</td>
                </tr>
                : contracts.map((d: DogovorType) =>
                    <tr key={d.id} className={d.end < Date.now() ? "table-danger disabled" : ""}
                        style={{cursor: "pointer"}} onClick={() => {
                        setCurrentContract(d);
                        setShowModal(true);
                    }}>
                        <td>{d.number}</td>
                        <td>{d.formattedStart}</td>
                        <td>{d.formattedEnd}</td>
                        <td>{d.customer.name}</td>
                        <td>{d.executor.name}</td>
                    </tr>
                )}
            </tbody>
        </table>

        <AddContractModal visibleState={[showModal, setShowModal]}
                          contractState={[currentContract, setCurrentContract]}/>
    </div>);
}
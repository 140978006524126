import React, {ReactElement, useEffect, useState} from "react";
import {REST} from "../../REST";
import {Loader} from "../../Loader";
import {FileType} from "../../types/FileType";

export function TabDocuments(props: any): ReactElement {
    const [documents, setDocuments] = useState<FileType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        REST.getFileByType("water_docs").then((d: FileType[]) => {
            setDocuments(d);
            setLoading(false);
        });
    }, [loading]);

    if (loading) {
        return (<div className={"tab-pane fade show active"} id="nav-documents" role="tabpanel"
                     aria-labelledby="nav-documents-tab" style={{backgroundColor: "white", padding: "10px", display: "flex", flexDirection: "column"}}><Loader/></div>);
    }

    return (
        <div className={"tab-pane fade show active"} id="nav-documents" role="tabpanel" aria-labelledby="nav-documents-tab" style={{backgroundColor: "white", padding: "10px", display: "flex", flexDirection: "column"}}>
            <div className="list-group">
                {documents.length === 0
                    ? <span className="list-group-item text-center">Нет доступных документов</span>
                    : documents.map((d: FileType) => <a key={d.id} href={"#"} onClick={() => REST.download(d)}
                                                            className="list-group-item list-group-item-action list-group-item-default">{d.name}</a>)
                }
            </div>
        </div>
    );
}

import React, {ReactElement, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {REST} from "../../../REST";
import {NotificationType} from "../../../types/NotificationType";

export function NotificationModal(props: any): ReactElement {
    const [visible, setVisible] = props.visibleState;
    const [currentNotification, setCurrentNotification] = props.notificationState;

    const hideMe = () => {
        setCurrentNotification(null);
        setVisible(false);
    }

    if (!visible) {
        return (<></>);
    }

    return (
        <div className={"ModalBackground"}>
            <div className={"Modal"}>
                <div className={"ModalHeader"} style={{justifyContent: "space-between"}}>
                    {currentNotification === null || currentNotification === undefined
                        ? "Новое уведомление"
                        : "Уведомление №" + currentNotification?.id
                    }
                    <div onClick={() => hideMe()} style={{cursor: "pointer"}}>
                        <FontAwesomeIcon icon={faXmark}/>
                    </div>
                </div>

                <div className={"ModalBody"}>


                </div>

                <div className={"ModalFooter"}>
                    {currentNotification === null || currentNotification === undefined
                        ? <div className="Button Primary" style={{marginLeft: "10px"}} onClick={() => {
                        }}>Создать</div>
                        : ""
                    }
                    <div className="Button" style={{marginLeft: "10px"}}
                         onClick={() => hideMe()}>Отмена
                    </div>
                </div>

            </div>
        </div>
    );
}
import React, {ReactElement, useState} from "react";
import {EmployeeType} from "../types/EmployeeType";
import {Utils} from "../utils/Utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBuilding, faFileSignature} from "@fortawesome/free-solid-svg-icons";
import {TabAdminCompany} from "./TabAdminCompany";
import {TabAdminContract} from "./TabAdminContract";

export function AdminIndex(props: any): ReactElement {
    const me: EmployeeType = props.me;

    const [activeTab, setActiveTab] = useState<number>(
        Utils.hasRole("admin_company")
            ? 0
            : (Utils.hasRole("admin_contract")
                ? 1
                : 0));

    return (
        <div className={'main-page'}>
            <div style={{
                display: "flex",
                marginTop: "16px",
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
                backgroundColor: "rgb(255, 255, 255)",
                height: "44px",
                padding: "8px 12px 8px 12px",
                borderBottom: "1px solid var(--otb-background)"
            }}>

                {Utils.hasRole("admin_company")
                    ? <div className={"NavLink" + (activeTab === 0 ? " Active" : "")} onClick={() => setActiveTab(0)}>
                        <FontAwesomeIcon icon={faBuilding}
                                         style={{height: "16px", width: "16px", paddingRight: "6px"}}/>
                        Организации
                    </div>
                    : ""
                }

                {Utils.hasRole("admin_contract")
                    ? <div className={"NavLink" + (activeTab === 1 ? " Active" : "")} onClick={() => setActiveTab(1)}>
                        <FontAwesomeIcon icon={faFileSignature}
                                         style={{height: "16px", width: "16px", paddingRight: "6px"}}/>
                        Договора
                    </div>
                    : ""
                }

            </div>

            <div className="tab-content">
                {Utils.hasRole("admin_company") && activeTab === 0 ? <TabAdminCompany me={me}/> : ""}
                {Utils.hasRole("admin_contract") && activeTab === 1 ? <TabAdminContract me={me}/> : ""}
            </div>
        </div>
    )

}
import React, {ReactElement, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {REST} from "../../REST";
import {Loader} from "../../Loader";
import {FlatContractDataType} from "../../types/ContractType";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faCheck, faDownload, faEnvelope, faXmark} from "@fortawesome/free-solid-svg-icons";
import {EmployeeType} from "../../types/EmployeeType";

export function ContractDKHAPage(props: any): ReactElement {
    const minFiles: number = 2;

    const me: EmployeeType = props.me;
    const isCustomer = me?.roles.some(r => r.code === "customer") ?? false;
    const isExecutor = me?.roles.some(r => r.code === "executor") ?? false;
    const isOperator = me?.roles.some(r => r.code === "operator") ?? false;
    const cdi = useRef<HTMLInputElement>(null);
    const executorFio = useRef<HTMLDivElement>(null);
    const dateOfCheck = useRef<HTMLDivElement>(null);
    const uploadFiles = useRef<HTMLInputElement>(null);
    const checkTime = useRef<HTMLInputElement>(null);
    const checkType = useRef<HTMLInputElement>(null);
    const violationCount = useRef<HTMLInputElement>(null);
    const violationObjectsCount = useRef<HTMLInputElement>(null);
    const description = useRef<HTMLTextAreaElement>(null);

    const {id} = useParams<string>();
    const {dkha} = useParams<string>();

    const [contractData, setContractData] = useState<FlatContractDataType[]>();
    const [loading, setLoading] = useState(true);
    const [canSend, setCanSend] = useState<boolean>(false);

    useEffect(() => {
        REST.getContractDataById(parseInt(id!), parseInt(dkha!)).then(cd => {
            setContractData(cd);
            setLoading(false);
        });
    }, [id, dkha]);

    if (loading)
        return (<main className="container mt-1">
            <Loader/>
        </main>);

    const contractData2Btn = (cd: FlatContractDataType) => {
        switch (cd.status) {
            case 0:
                return "btn-secondary disabled";
            case 1:
                return "btn-primary" + (isCustomer ? " disabled" : "");
            case 2:
                return "btn-warning" + (isCustomer ? " disabled" : "");
            case 3:
                return "btn-danger" + (isCustomer ? " disabled" : "");
            case 4:
                return "btn-success" + (isOperator || isExecutor ? " disabled" : "");
        }
    }

    const checkCanSend = () => {
        if ((uploadFiles.current!.files?.length ?? 0) >= minFiles
            && checkTime.current!.value.length > 0
            && checkType.current!.value.length > 0
            && violationCount.current!.value.length > 0
            && violationObjectsCount.current!.value.length > 0
        ) {
            setCanSend(true);
        } else {
            setCanSend(false);
        }
    }

    const sendDailyReport = () => {
        let formData = new FormData();

        let files: HTMLInputElement = uploadFiles.current!;
        for (let i = 0; i < files.files!.length; i++) {
            formData.append("file", files.files!.item(i)!);
        }
        //formData.append("eid", me.id.toString());
        formData.append("cdi", cdi.current!.value.toString());
        formData.append("time", checkTime.current!.value);
        formData.append("type", checkType.current!.value);
        formData.append("violations", violationCount.current!.value);
        formData.append("violationObjects", violationObjectsCount.current!.value);
        formData.append("description", description.current!.value ?? '');

        REST.uploadDailyReport(formData).then((crid) => {
            checkType.current!.value = '';
            violationCount.current!.value = violationCount.current!.defaultValue;
            violationObjectsCount.current!.value = violationObjectsCount.current!.defaultValue;
            description.current!.value = '';
            uploadFiles.current!.value = '';
            setCanSend(false);

            document.getElementById("cdi" + cdi.current!.value.toString())!.className = "m-1 btn btn-success disabled";
        });
        ////TODO: add spinner while upload
        ////TODO: add error button on fail
    };

    return (<main className="container mt-1">

        <section className="btn-group">
            <a className="btn btn-success" style={{cursor: "pointer"}}
               href={"/road/contract/" + contractData![0].cid}><FontAwesomeIcon
                icon={faArrowLeft}/>&nbsp;Назад</a>
            <div className="btn-group files" role="group">

            </div>
        </section>

        <section>
            <h5 style={{textAlign: "center"}}>{"Контракт №" + contractData![0].number + " от " + contractData![0].formattedCdate}</h5>
            <div>
                <div className="input-group mb-3">
                    <span className="input-group-text">Заказчик</span>
                    <div className="form-control">{contractData![0].customer}</div>
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text">Исполнитель</span>
                    <div className="form-control">{contractData![0].executor}</div>
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text">Место оказания услуг</span>
                    <div className="form-control">{contractData![0].region}</div>
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text">Наименование объекта</span>
                    <div className="form-control">{contractData![0].name}</div>
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text">Реестровый номер, ДХА</span>
                    <div className="form-control">{contractData![0].did.toString().padStart(7, '0')}</div>
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text">Протяженность, м</span>
                    <div
                        className="form-control">{contractData![0].length === 0 ? "нет" : contractData![0].length}</div>
                    <span className="input-group-text">Категория</span>
                    <div className="form-control">{contractData![0].category}</div>
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text">Координаты</span>
                    <span className="input-group-text">долгота</span>
                    <div className="form-control">{contractData![0].lon === 0 ? "нет" : contractData![0].lon}</div>
                    <span className="input-group-text">широта</span>
                    <div className="form-control">{contractData![0].lat === 0 ? "нет" : contractData![0].lat}</div>
                </div>

                <div className={"d-flex"} style={{flexWrap: "wrap"}}>
                    {contractData!.map(cd =>
                        <button key={cd.id} id={"cdi" + cd.id.toString()}
                                className={"m-1 btn " + contractData2Btn(cd)}
                                style={{cursor: "pointer", flex: "1 0 13%"}} data-bs-toggle="modal"
                                data-bs-target="#dayModal"
                                onClick={() => {
                                    dateOfCheck.current!.innerText = cd.formattedDate;
                                    cdi.current!.value = cd.id.toString();
                                    executorFio.current!.innerText = cd.status === 4 ? cd.fio : me.fio;
                                }}>{cd.formattedDate}</button>
                    )}
                </div>

            </div>
        </section>


        <div className="modal fade" id="dayModal" aria-labelledby="dayModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="dayModalLabel">Ежедневный отчет</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <input type={"hidden"} name={"cdi"} ref={cdi}/>
                        <div className="input-group mb-3">
                            <span className="input-group-text">ФИО осматривающего</span>
                            <div className="form-control disabled" ref={executorFio}></div>
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text">ОТИ</span>
                            <div className="form-control disabled">{contractData![0].name}</div>
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text">Дата проверки</span>
                            <div className="form-control disabled" ref={dateOfCheck}/>
                            <span className="input-group-text">время</span>
                            <input className="form-control" name={"checkTime"} type={"time"} required
                                   defaultValue={new Date().getHours().toString().padStart(2, '0') + ":" + new Date().getMinutes().toString().padStart(2, '0')}
                                   ref={checkTime}
                                   onChange={(e) => checkCanSend()}/>
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text">Вид проверки</span>
                            <input className="form-control" type={"text"} name={"checkType"} defaultValue={"Не реже 1 раза в 24 часа"}
                                   onChange={(e) => checkCanSend()} ref={checkType}/>
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text">Выявленные</span>
                            <span className="input-group-text">нарушители</span>
                            <input className="form-control" type={"number"} name={"violationCount"} defaultValue={0}
                                   min={0} required ref={violationCount} onChange={(e) => checkCanSend()}/>
                            <span className="input-group-text">предметы</span>
                            <input className="form-control" type={"number"} name={"violationObjectsCount"} defaultValue={0}
                                   min={0} required ref={violationObjectsCount} onChange={(e) => checkCanSend()}/>
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text">Описание недостатов и дефектов</span>
                            <textarea className="form-control" name={"description"} rows={4} required ref={description}
                                      onChange={(e) => checkCanSend()} defaultValue={"Не выявлено"}></textarea>
                        </div>
                        <hr/>
                        {isCustomer ? "" : <>
                            <div><i className="bi bi-paperclip"></i>&nbsp;Прикрепленные фото:</div>
                            <ul className="list-group" id="attached-files">

                            </ul>
                            <br/>
                            <div className="input-group mb-3">
                                <input type="file" id="photo" name="photo" className="form-control" multiple={true}
                                       required
                                       ref={uploadFiles} onChange={(e) => checkCanSend()}/>
                            </div>
                        </>
                        }
                    </div>
                    <div className="modal-footer">
                        <div className={"btn-group"}>
                            <div className="btn btn-secondary disabled"
                                 onClick={() => alert('Будет произведена отправка в ЕГИС')}><FontAwesomeIcon
                                icon={faEnvelope}/>&nbsp;Отправить в ЕГИС ОТБ
                            </div>
                            {isCustomer ? <div className="btn btn-primary disabled"
                                               onClick={() => REST.downloadDailyReport(BigInt(cdi.current!.value), dateOfCheck.current!.innerText, dateOfCheck.current!.innerText, 'docx')}>
                                    <FontAwesomeIcon
                                        icon={faDownload}/>&nbsp;Скачать
                                </div>
                                : ""}
                        </div>
                        <div className={"btn-group"}>
                            <button className="btn btn-danger" data-bs-dismiss="modal" type={"reset"}><FontAwesomeIcon
                                icon={faXmark}/>&nbsp;Отмена
                            </button>
                            <button className={"btn btn-success" + (canSend && isExecutor ? "" : " disabled")}
                                    data-bs-dismiss="modal"
                                    type={"button"} onClick={() => sendDailyReport()}><FontAwesomeIcon
                                icon={faCheck}/>&nbsp;Отправить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </main>)
}
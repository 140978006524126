import React, {ChangeEventHandler, ReactElement, useEffect, useRef, useState} from "react";
import {RegionType} from "../../types/RegionType";
import {REST} from "../../REST";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faFloppyDisk} from "@fortawesome/free-solid-svg-icons";
import {ObjectTr} from "../../component/part/ObjectTr";
import {Loader} from "../../Loader";
import {DKHAType} from "../../types/DKHAType";
import {NewContractType} from "../../types/ContractType";

export function NewContractPage(props: any): ReactElement {
    const [regions, setRegions] = useState<RegionType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [canSave, setCanSave] = useState<boolean>(false);

    const contractNumber = useRef<HTMLInputElement>(null);
    const contractDate = useRef<HTMLInputElement>(null);
    const customerName = useRef<HTMLInputElement>(null);
    const executorName = useRef<HTMLInputElement>(null);
    const customerINN = useRef<HTMLInputElement>(null);
    const executorINN = useRef<HTMLInputElement>(null);
    const contractStart = useRef<HTMLInputElement>(null);
    const contractEnd = useRef<HTMLInputElement>(null);
    const region = useRef<HTMLSelectElement>(null);
    const tBody = useRef<HTMLTableSectionElement>(null);

    //const [objectList, setObjectList] = useState<ReactElement[]>([]);
    //var countLines = 0;

    useEffect(() => {
        REST.getRegion().then((r: RegionType[]) => {
            setRegions(r);
            setLoading(false);
        })
    }, []);

    if (loading) {
        return (<div>
            <Loader/>
        </div>);
    }

    const handleInnChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        if (event.target.value.length === 10 || event.target.value.length === 12) {
            REST.getCompanyByInn(parseInt(event.target.value), true).then(c => {
                if (event.target.name === "customer_inn") {
                    customerName.current!.value = c.name ?? ""
                } else if (event.target.name === "executor_inn") {
                    executorName.current!.value = c.name ?? ""
                }
            })
        }
        checkSave();
    }

    const checkSave = () => {
        if(contractNumber.current!.value.length > 0
            //&& tBody.current!.rows.length > 1
            && contractDate.current!.value.length > 0
            && contractStart.current!.value.length > 0
            && contractEnd.current!.value.length > 0
            && customerINN.current!.value.length > 0
            && executorINN.current!.value.length > 0) {
            setCanSave(true);
        } else {
            setCanSave(false);
        }
    }
    const createRequest = (event: any) => {
        let objects: DKHAType[] = [];
        /*objectList.forEach(o => {
            var obj: DKHAType;
            if (o.props.isObject) {
                obj = {
                    name: o.props.name,
                    length: o.props.length,
                    category: o.props.category,
                    number: o.props.dha
                }
            } else {
                return;
            }
            objects.push(obj);
        });*/

        const trs: HTMLCollectionOf<HTMLTableRowElement> = tBody.current!.rows;
        for (var i = 0; i < trs.length - 1; i++) {
            var obj: DKHAType;
            obj = {
                number: parseInt(trs.item(i)!.cells.item(1)!.innerText),
                name: trs.item(i)!.cells.item(2)!.innerText,
                length: parseFloat(trs.item(i)!.cells.item(3)!.innerText),
                category: parseInt(trs.item(i)!.cells.item(4)!.innerText)
            }
            objects.push(obj);
        }
        let newContract: NewContractType = {
            number: contractNumber.current!.value,
            date: BigInt(Date.parse(contractDate.current!.value).toString()),
            start: BigInt(Date.parse(contractStart.current!.value).toString()),
            finish: BigInt(Date.parse(contractEnd.current!.value).toString()),
            customer: BigInt(customerINN.current!.value),
            executor: BigInt(executorINN.current!.value),
            region: BigInt(region.current!.value),
            objects: objects
        }

        REST.createContract(newContract).then(c => {
            window.location.href = '/';
        });
    }

    return (<main className="container mt-1">
        <section style={{display: "flex", justifyContent: "space-between", alignItems: "center"}} className={"mb-3"}>
            <a className="btn btn-success" style={{cursor: "pointer"}} href="/"><FontAwesomeIcon
                icon={faArrowLeft}/>&nbsp;Назад</a>
            <h5 style={{textAlign: "center"}}>Новый контракт</h5>
            <button className={"btn btn-success"+ (canSave ? "": " disabled")} style={{cursor: "pointer"}} onClick={createRequest}>
                <FontAwesomeIcon
                    icon={faFloppyDisk}/>&nbsp;Сохранить
            </button>
        </section>

        <section>
            <div>
                <div className="input-group mb-3">
                    <span className="input-group-text">Номер контракта</span>
                    <input className="form-control" id="contract_number" name="contract_number" required type={"text"}
                           ref={contractNumber} onChange={() => checkSave()}/>
                    <span className="input-group-text">дата</span>
                    <input className="form-control" id="contract_date" name="contract_date" required type={"date"}
                           ref={contractDate} onChange={() => checkSave()}/>
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text">Заказчик</span>
                    <span className="input-group-text">ИНН</span>
                    <input className="form-control" id="customer_inn" name="customer_inn" required type={"number"}
                           placeholder={"7712345678"} onChange={handleInnChange} ref={customerINN}/>
                    <span className="input-group-text">название</span>
                    <input className="form-control disabled" id="customer_name" name="customer_name" required type={"text"}
                           placeholder={"ООО \"Рога и Копыта\""} ref={customerName}/>
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text">Исполнитель</span>
                    <span className="input-group-text">ИНН</span>
                    <input className="form-control" id="executor_inn" name="executor_inn" required type={"number"}
                           placeholder={"7712345678"} onChange={handleInnChange} ref={executorINN}/>
                    <span className="input-group-text">название</span>
                    <input className="form-control disabled" id="executor_name" name="executor_name" required type={"text"}
                           placeholder={"ООО \"Рога и Копыта\""} ref={executorName}/>
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text">Срок контракта</span>
                    <span className="input-group-text">с</span>
                    <input className="form-control" id="contract_start" name="contract_start" required type={"date"}
                           ref={contractStart} onChange={() => checkSave()}/>
                    <span className="input-group-text">по</span>
                    <input className="form-control" id="contract_end" name="contract_end" required type={"date"}
                           ref={contractEnd} onChange={() => checkSave()}/>
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text">Место оказания услуг</span>
                    <select className="form-select" id="region" name="region" required ref={region}>
                        {regions.map((r: RegionType) => <option value={r.id}
                                                                key={r.id}>{r.name}</option>)}
                    </select>
                </div>

                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th scope="col" className={"text-center"}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        <th scope="col" className={"text-center"}>Реестровый номер, ДХА</th>
                        <th scope="col" className={"text-center w-50"}>Наименование объекта</th>
                        <th scope="col" className={"text-center"}>Протяженность, м</th>
                        <th scope="col" className={"text-center"}>Категория</th>
                    </tr>
                    </thead>
                    <tbody id={"rootObjects"} ref={tBody}>
                    <ObjectTr/>
                    </tbody>
                </table>

            </div>
        </section>
    </main>)
}

import React, {ReactElement, useEffect, useState} from "react";
import {ContractType} from "../../types/ContractType";
import {REST} from "../../REST";
import {Loader} from "../../Loader";
import {StatusContract} from "../../component/part/StatusContract";

export function TabOutContract(props: any): ReactElement {
    const [contracts, setContracts] = useState<ContractType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        REST.getCustomerContractList().then((c: ContractType[]) => {
            setContracts(c);
            setLoading(false);
        });
    }, [loading]);

    if (loading) {
        return (<div className={"tab-pane fade show active"} id="nav-customer-contracts" role="tabpanel"
                     aria-labelledby="nav-passports-tab" style={{backgroundColor: "white"}}><Loader/></div>);
    }

    return (
        <div className={"tab-pane fade show active"} id="nav-customer-contracts" role="tabpanel" aria-labelledby="nav-contracts-tab" style={{backgroundColor: "white"}}>
            <div className="dropdown" style={{paddingTop: "10px", paddingBottom: "10px", display: "flex"}}>
                <StatusContract/>
            </div>

            <div className="list-group">
                {contracts.length === 0 ?
                    <span className="list-group-item text-center" style={{cursor: "pointer"}}>Нет контрактов</span> :
                    contracts.map((c: ContractType) => <a key={c.id} href={"/contract/" + c.id}
                                                          className={"list-group-item list-group-item-action list-group-item-" + (c.status === 0 ? "default" : (c.status === 1 ? "primary" : (c.status === 4 ? "success" : (c.status === 2 ? "warning" : "danger"))))}>{"Контракт №" + c.number + " от " + c.formattedDate}</a>)
                }
            </div>
        </div>
    );
}

import React, {ReactElement} from "react";
import {Loader} from "../../Loader";

export function TabAnalytics(props: any): ReactElement {
    return (<div className={"tab-pane fade show active"} id="nav-documents" role="tabpanel"
                 aria-labelledby="nav-documents-tab"
                 style={{backgroundColor: "white", padding: "10px", display: "flex", flexDirection: "column"}}><Loader/>
    </div>);

}

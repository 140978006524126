import React, {ReactElement, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {REST} from "../../REST";
import {Loader} from "../../Loader";
import {FlatContractType} from "../../types/ContractType";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faDownload, faFileWord, faFileZipper} from "@fortawesome/free-solid-svg-icons";
import {EmployeeType} from "../../types/EmployeeType";

export function ContractPage(props: any): ReactElement {
    const sevenDays = 604800000;//in ms
    const oneDay = 86400000;//in ms
    const me: EmployeeType = props.me;
    const isCustomer = me?.roles.some(r => r.code === "customer") ?? false;
    const isExecutor = me?.roles.some(r => r.code === "executor") ?? false;
    const {id} = useParams<string>();

    const [contract, setContract] = useState<FlatContractType>();
    const [loading, setLoading] = useState(true);
    const [canReport, setCanReport] = useState<boolean>(false);
    const periodStart = useRef<HTMLInputElement>(null);
    const periodFinish = useRef<HTMLInputElement>(null);
    const [canDailyReport, setCanDailyReport] = useState<boolean>(true);
    const [canWeeklyReport, setCanWeeklyReport] = useState<boolean>(true);

    const shortFio = (fio: string) => {
        let parts: string[] = fio.split(" ");
        if (parts.length > 1)
            return parts[0] + " " + parts.slice(1).map(p => p.charAt(0)).join(".") + ".";
        return "";
    }

    useEffect(() => {
        REST.getContractById(parseInt(id!)).then(c => {
            setContract(c);
            setLoading(false);
        });
    }, [id]);

    if (loading)
        return (<main className="container mt-1">
            <Loader/>
        </main>);

    const checkCanReport = () => {
        if (Date.parse(periodFinish.current!.value) >= Date.parse(periodStart.current!.value)) {
            setCanReport(true);
        } else {
            setCanReport(false);
        }
    }

    return (<main className="container mt-1">

        <section className="btn-group">
            <a className="btn btn-success" style={{cursor: "pointer"}} href="/"><FontAwesomeIcon
                icon={faArrowLeft}/>&nbsp;Назад</a>
            {isCustomer
                ? <>
                    <button className={"btn btn-primary disabled"} data-bs-toggle="modal"
                            data-bs-target="#certificateModal"><FontAwesomeIcon
                        icon={faFileWord}/>&nbsp;Справка за период
                    </button>
                    <button className={"btn btn-warning" + (canDailyReport ? "" : " disabled")}
                            onClick={() => {
                                setCanDailyReport(false);
                                REST.downloadZipReport(contract!.id, contract!.number, Date.now() - oneDay, Date.now()).then(() =>
                                    setCanDailyReport(true)
                                )
                            }
                            }>
                        <FontAwesomeIcon icon={faFileZipper}/>&nbsp;{"Отчет за день"}
                    </button>
                    <button className={"btn btn-warning disabled" + (canWeeklyReport ? "" : " disabled")}
                            onClick={() => {
                                setCanWeeklyReport(false);
                                REST.downloadZipReport(contract!.id, contract!.number, Date.now() - sevenDays, Date.now()).then(() =>
                                    setCanWeeklyReport(true)
                                )
                            }
                            }>
                        <FontAwesomeIcon
                            icon={faFileZipper}/>&nbsp;Отчет за неделю
                    </button>
                </>
                : ""
            }
        </section>

        <section className={"mt-2"}>
            <h5 style={{textAlign: "center"}}>{"Контракт №" + contract!.number + " от " + contract!.formattedDate}</h5>
            <div>
                <div className="input-group mb-3">
                    <span className="input-group-text">Заказчик</span>
                    <div className="form-control">{contract!.customer}</div>
                    <span className="input-group-text">Исполнитель</span>
                    <div className="form-control">{contract!.executor}</div>
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text">Срок контракта</span>
                    <span className="input-group-text">с</span>
                    <div className="form-control">{contract!.formattedStart}</div>
                    <span className="input-group-text">по</span>
                    <div className="form-control">{contract!.formattedFinish}</div>
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text">Место оказания услуг</span>
                    <div className="form-control">{contract!.region}</div>
                </div>

                <table className="table table-bordered table-hover">
                    <thead>
                    <tr>
                        <th scope="col" className={"text-center"}>№ п/п</th>
                        <th scope="col" className={"text-center"}>Реестровый номер, ДХА</th>
                        <th scope="col" className={"text-center w-50"}>Наименование объекта</th>
                        {isCustomer ? "" :
                            <>
                                <th scope="col" className={"text-center"}>Протяженность, м</th>
                                <th scope="col" className={"text-center"}>Категория</th>
                            </>
                        }
                        <th scope="col" className={"text-center"}>Последний осмотр</th>
                        <th scope="col" className={"text-center"}>Исполнитель</th>
                        {isCustomer ? "" :
                            <>
                                <th scope="col" className={"text-center"}>Статус</th>
                            </>
                        }
                    </tr>
                    </thead>
                    <tbody id={"rootObjects"}>
                    {isCustomer
                        ? contract!.objects.map((d, index) =>
                            <tr key={d.number} className={"table-default"}>
                                <td className={"text-center"}>{index + 1}</td>
                                <td className={"text-center"}>{d.number.toString().padStart(7, '0')}</td>
                                <td className={"text-center"}>{d.name}</td>
                                <td className={"text-center"}>{d.lastReport}</td>
                                <td className={"text-center"}>{shortFio(d.executor ?? "")}</td>
                            </tr>
                        )
                        : contract!.objects.map((d, index) =>
                            <tr key={d.number} style={{cursor: "pointer"}} onClick={(e) => {
                                window.location.href = '/road/contract/' + contract!.id + '/' + d.number
                            }} className={"table-default"}>
                                <td className={"text-center"}>{index + 1}</td>
                                <td className={"text-center"}>{d.number.toString().padStart(7, '0')}</td>
                                <td className={"text-center"}>{d.name}</td>
                                <td className={"text-center"}>{d.length === 0 ? "" : d.length}</td>
                                <td className={"text-center"}>{d.category}</td>
                                <td className={"text-center"}>{d.lastReport}</td>
                                <td className={"text-center"}>{d.executor}</td>
                                <td className={"text-center"}>{d.status}</td>
                            </tr>
                        )}
                    </tbody>
                </table>

            </div>
        </section>

        {isCustomer
            ? <div className="modal fade" id="certificateModal" aria-labelledby="certificatetModalLabel"
                   aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="certificateModalLabel">Справка за период</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="input-group mb-3">
                                <span className="input-group-text">Период справки</span>
                                <span className="input-group-text">с</span>
                                <input className="form-control" type={"date"} name={"periodStart"}
                                       onChange={(e) => checkCanReport()} ref={periodStart}/>
                                <span className="input-group-text">по</span>
                                <input className="form-control" type={"date"} name={"periodFinish"}
                                       onChange={(e) => checkCanReport()} ref={periodFinish}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className={"btn-group"}>
                                <div className={"btn btn-primary" + (canReport ? "" : " disabled")}
                                     data-bs-dismiss="modal"
                                     onClick={() => {
                                         REST.downloadDailyReport(contract!.id, periodStart.current!.value, periodFinish.current!.value, 'docx');
                                         periodStart.current!.value = '';
                                         periodFinish.current!.value = '';
                                     }}><FontAwesomeIcon
                                    icon={faDownload}/>&nbsp;Сформировать и скачать
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            : ""
        }
    </main>)
}
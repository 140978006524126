import React, {ReactElement} from "react";
import {REST} from "../REST";

export function LegalPage(props: any): ReactElement {
    return (<div className={'Page'}>
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "16px",
            borderRadius: "12px",
            backgroundColor: "rgb(255, 255, 255)",
            padding: "8px 12px 8px 12px",
            borderBottom: "1px solid var(--otb-background)",
            minWidth: "40%"
        }}>


            <div style={{marginLeft: "105px", marginRight: "105px", paddingTop: "38px", paddingBottom: "65px", textAlign: "center"}}>
                <div style={{fontSize: "23px", fontWeight: "700", paddingBottom: "8px"}}>Правовая информация</div>
                <div style={{fontSize: "14px"}}>{"Сервисы " + REST.title + " позволяют вам проводить досмотры и осмотры."}</div>
                <div style={{paddingTop: "49px"}}>

                    <div style={{textAlign: "left"}}>
                        <a href={"#"} style={{textDecoration: "none", color: "rgb(42, 88, 133)", fontSize: "15px", fontWeight: "500", paddingBottom: "4px"}}>Правила пользования сайтом</a><br/>
                        <span style={{fontSize: "14px"}}>{"Ознакомьтесь с правилами для пользователей " + REST.title + "."}</span>
                    </div>

                    <hr style={{marginBottom: "16px", marginTop: "16px"}}/>

                    <div style={{textAlign: "left"}}>
                        <a href={"#"} style={{textDecoration: "none", color: "rgb(42, 88, 133)", fontSize: "15px", fontWeight: "500", paddingBottom: "4px"}}>Лицензионное соглашение</a><br/>
                        <span style={{fontSize: "14px"}}>{"Узнайте, на каких условиях пользователям предоставляется право использования " + REST.title + "."}</span>
                    </div>

                    <hr style={{marginBottom: "16px", marginTop: "16px"}}/>

                    <div style={{textAlign: "left"}}>
                        <a href={"#"} style={{textDecoration: "none", color: "rgb(42, 88, 133)", fontSize: "15px", fontWeight: "500", paddingBottom: "4px"}}>Оферта о заключении договора</a><br/>
                        <span style={{fontSize: "14px"}}>Условия оказания услуг, запреты, ограничения.</span>
                    </div>
                </div>
            </div>


        </div>
    </div>);
}
import React, {ReactElement} from "react";
import '../../css/status.css';
export function StatusList(props: any) : ReactElement {
    return (<div className={"m-1"}>
        <label className="text-dark">Статусы заявок:&nbsp;</label>
        <p className={"status-list d-inline"}>
            <span className="badge bg-light" style={{height: "16px", fontWeight: "400"}}>Новая</span>
            <span className="badge primary" style={{height: "16px", fontWeight: "400"}}>На рассмотрении</span>
            <span className="badge warning" style={{height: "16px", fontWeight: "400"}}>В работе</span>
            <span className="badge success" style={{height: "16px", fontWeight: "400"}}>Исполнена</span>
            <span className="badge danger" style={{height: "16px", fontWeight: "400"}}>Отклонена</span>
        </p>
    </div>);
}
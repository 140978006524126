import React, {ReactElement, useEffect, useState} from "react";
import {Loader} from "../Loader";
import {CompanyType} from "../types/CompanyType";
import {REST} from "../REST";
import {RegisterCompanyModal} from "./RegisterCompanyModal";

export function TabAdminCompany(props: any): ReactElement {
    const [companies, setCompanies] = useState<CompanyType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentCompany, setCurrentCompany] = useState<CompanyType | null>(null);

    useEffect(() => {
        REST.getCompanies().then((c: CompanyType[]) => {
            setCompanies(c)
            setLoading(false);
        });
    }, []);

    if (loading) {
        return (<div className="tab-pane fade show active" id="nav-admin-company" role="tabpanel"
                     aria-labelledby="nav-admin-company-tab"
                     style={{backgroundColor: "white", padding: "10px", display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", flexDirection: "row", paddingBottom: "10px"}}>
                <div className={"Button Primary Disabled"}>Подключить организацию</div>
            </div>
            <table className="table table-hover table-bordered">
                <thead>
                <tr>
                    <th>ИНН</th>
                    <th>Название организации</th>
                    <th>Руководитель</th>
                    <th>Адрес</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td colSpan={4} style={{textAlign: "center"}}><Loader/></td>
                </tr>
                </tbody>
            </table>
        </div>);
    }

    return (
        <div className="tab-pane fade show active" id="nav-admin-company" role="tabpanel"
             aria-labelledby="nav-admin-company-tab"
             style={{backgroundColor: "white", padding: "10px", display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", flexDirection: "row", paddingBottom: "10px"}}>
                <div className={"Button Primary"} onClick={() => setShowModal(true)}>Подключить организацию</div>
            </div>

            <table className="table table-hover table-bordered">
                <thead>
                <tr>
                    <th>ИНН</th>
                    <th>Название организации</th>
                    <th>Руководитель</th>
                    <th>Адрес</th>
                </tr>
                </thead>
                <tbody>
                {companies.length === 0
                    ? <tr>
                        <td colSpan={4}>Нет подключенных организаций</td>
                    </tr>
                    : companies.map((c: CompanyType) =>
                        <tr key={c.inn} className={c.is_closed ? "table-danger disabled" : ""}
                            style={{cursor: "pointer"}} onClick={() => {
                            setCurrentCompany(c);
                            setShowModal(true);
                        }}>
                            <td>{c.inn}</td>
                            <td>{c.name}</td>
                            <td>{c.director}</td>
                            <td>{c.address ?? ""}</td>
                        </tr>
                    )}
                </tbody>
            </table>

            <RegisterCompanyModal visibleState={[showModal, setShowModal]}
                                  companyState={[currentCompany, setCurrentCompany]}/>
        </div>
    );
}

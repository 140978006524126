import React, {ChangeEventHandler, ReactElement, useEffect, useRef, useState} from "react";
import {CountryType} from "../../../types/CountryType";
import {REST} from "../../../REST";
import {RequestType} from "../../../types/RequestType";
import {Loader} from "../../../Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {RegionType} from "../../../types/RegionType";

export function NewRequestModal(props: any): ReactElement {
    const [visible, setVisible] = props.visibleState;
    //const [currentRequest, setCurrentRequest] = props.currentRequestState;
    const [countries, setCountries] = useState<CountryType[]>([]);
    const [regions, setRegions] = useState<RegionType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [canSave, setCanSave] = useState<boolean>(false);

    const nameCargo = useRef<HTMLInputElement>(null);
    const nameConsignee = useRef<HTMLInputElement>(null);
    const contact = useRef<HTMLInputElement>(null);
    const imonumber = useRef<HTMLInputElement>(null);
    const nameOperator = useRef<HTMLInputElement>(null);
    const nameMaster = useRef<HTMLInputElement>(null);
    const otbname = useRef<HTMLInputElement>(null);
    const registrynumber = useRef<HTMLInputElement>(null);
    const reestrnumber = useRef<HTMLInputElement>(null);
    const inport = useRef<HTMLInputElement>(null);
    const fromport = useRef<HTMLInputElement>(null);
    const outport = useRef<HTMLInputElement>(null);
    const target = useRef<HTMLInputElement>(null);
    const place = useRef<HTMLInputElement>(null);
    const cargo = useRef<HTMLInputElement>(null);
    const count = useRef<HTMLInputElement>(null);
    const indatetime = useRef<HTMLInputElement>(null);
    const flag = useRef<HTMLSelectElement>(null);
    const incountry = useRef<HTMLSelectElement>(null);
    const fromcountry = useRef<HTMLSelectElement>(null);
    const outcountry = useRef<HTMLSelectElement>(null);
    const startloadtime = useRef<HTMLInputElement>(null);
    const endloadtime = useRef<HTMLInputElement>(null);
    const addressobject = useRef<HTMLInputElement>(null);
    const addressobjectregion = useRef<HTMLSelectElement>(null);
    const contactoperator = useRef<HTMLInputElement>(null);
    const contactmaster = useRef<HTMLInputElement>(null);
    const contactcargo = useRef<HTMLInputElement>(null);

    useEffect(() => {
        REST.getCountry().then((c: CountryType[]) => {
            setCountries(c);
            REST.getRegion().then((r: RegionType[]) => {
                setRegions(r);
                setLoading(false);
            });
        })
    }, []);

    const hideMe = () => {
        //setCurrentRequest(null);
        setVisible(false);
    }

    if (!visible) {
        return (<></>);
    }

    if (loading) {
        return (<div className={"ModalBackground"} onClick={() => hideMe()}>
                <div className={"Modal"} onClick={(e) => e.stopPropagation()}>
                    <div className={"ModalHeader"} style={{justifyContent: "space-between"}}>
                        Новая заявка
                        <div onClick={() => hideMe()} style={{cursor: "pointer"}}>
                            <FontAwesomeIcon icon={faXmark}/>
                        </div>
                    </div>
                    <div className={"ModalBody"}>

                        <Loader/>

                    </div>
                    <div className={"ModalFooter"}>
                        <div className="Button Primary">Создать</div>
                        <div className="Button" style={{marginLeft: "10px"}}
                             onClick={() => hideMe()}>Отмена
                        </div>
                    </div>

                </div>
            </div>
        );
    }

    const handleInnChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        if (event.target.value.length === 10 || event.target.value.length === 12) {
            REST.getCompanyByInn(parseInt(event.target.value), true).then(c => {
                if (event.target.name === "inncargo") {
                    nameCargo.current!.value = c.name ?? ""
                } else if (event.target.name === "innoperator") {
                    nameOperator.current!.value = c.name ?? ""
                } else if (event.target.name === "innmaster") {
                    nameMaster.current!.value = c.name ?? ""
                } else if (event.target.name === "innconsignee") {
                    nameConsignee.current!.value = c.name ?? ""
                }
                handleOnChange();
            })
        }
    }

    const handleIMOChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        if (event.target.value.length > 4) {
            REST.getVesselByIMO(parseInt(event.target.value)).then(v => {
                if (v.length > 0) {
                    otbname.current!.value = v[0].name ?? ""
                    for (let i = 0; i < flag.current!.children.length; i++) {
                        if (flag.current!.item(i)!.value === v[0].flag.toString())
                            flag.current!.item(i)!.selected = true;
                    }
                    registrynumber.current!.value = v[0].register_number ?? ""
                    reestrnumber.current!.value = v[0].registry_number === undefined ? "" : (v[0].registry_serial + " " + v[0].registry_number);

                    handleOnChange();
                }
            })
        }
    }

    const handleOnChange = () => {
        setCanSave((otbname.current?.value.length ?? 0) > 0 &&
            (imonumber.current?.value.length ?? 0) > 0 &&
            (registrynumber.current?.value.length ?? 0) > 0 &&
            (reestrnumber.current?.value.length ?? 0) > 0 &&
            (contact.current?.value.length ?? 0) > 0 &&
            (inport.current!.value.length ?? 0) > 0 &&
            (fromport.current?.value.length ?? 0) > 0 &&
            (outport.current?.value.length ?? 0) > 0 &&
            (target.current?.value.length ?? 0) > 0 &&
            (place.current?.value.length ?? 0) > 0 &&
            (cargo.current?.value.length ?? 0) > 0 &&
            (count.current?.value.length ?? 0) > 0 &&
            (indatetime.current?.value.length ?? 0) > 0 &&
            (startloadtime.current?.value.length ?? 0) > 0 &&
            (endloadtime.current?.value.length ?? 0) > 0 &&
            (addressobject.current?.value.length ?? 0) > 0 &&
            (addressobjectregion.current?.value.length ?? 0) > 0 &&
            (contactoperator.current?.value.length ?? 0) > 0 &&
            (contactmaster.current?.value.length ?? 0) > 0 &&
            nameCargo.current!.value.length > 0 &&
            nameOperator.current!.value.length > 0 &&
            nameMaster.current!.value.length > 0 &&
            nameConsignee.current!.value.length > 0 &&
            (contactcargo.current?.value.length ?? 0) > 0);
    }

    const createRequest = () => {
        let newRequest = {
            otbname: otbname.current!.value,
            flag: parseInt(flag.current!.value),
            imonumber: imonumber.current!.value,//TODO: long
            registrynumber: registrynumber.current!.value,
            reestrnumber: reestrnumber.current!.value,
            contact: contact.current!.value,
            inport: inport.current!.value,
            incountry: parseInt(incountry.current!.value),
            fromport: fromport.current!.value,
            fromcountry: parseInt(fromcountry.current!.value),
            outport: outport.current!.value,
            outcountry: parseInt(outcountry.current!.value),
            target: target.current!.value,
            place: place.current!.value,
            cargo: cargo.current!.value,
            count: count.current!.value,
            indatetime: indatetime.current!.value,
            startloadtime: startloadtime.current!.value,
            endloadtime: endloadtime.current!.value,
            addressobject: addressobject.current!.value,
            addressobjectregion: addressobjectregion.current!.value,
            nameoperator: nameOperator.current!.value,
            contactoperator: contactoperator.current!.value,
            mastername: nameMaster.current!.value,
            contactmaster: contactmaster.current!.value,
            namecargo: nameCargo.current!.value,
            contactcargo: contactcargo.current!.value,
            nameconsignee: nameConsignee.current!.value,
        }
        REST.createRequest(newRequest).then(r => {
            props.setRequests((prev: RequestType[]) => [r, ...prev]);
            hideMe();
        });
    }

    return (
        <div className={"ModalBackground"}>
            <div className={"Modal"}>
                <div className={"ModalHeader"} style={{justifyContent: "space-between"}}>
                    Новая заявка
                    <div onClick={() => hideMe()} style={{cursor: "pointer"}}>
                        <FontAwesomeIcon icon={faXmark}/>
                    </div>
                </div>


                <div className="modal-body">
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Наименование судна</span>
                        <input type="text" className="form-control" ref={otbname} onChange={() => handleOnChange()}/>
                        <span className="input-group-text">флаг</span>
                        <select className="form-select" ref={flag} defaultValue={0}>
                            {countries.map((c: CountryType) => <option value={c.id}
                                                                       key={c.id}>{c.name}</option>)}
                        </select>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">ИМО номер</span>
                        <input type="number" className="form-control" min="1" id="imonumber" name="imonumber"
                               onChange={handleIMOChange} placeholder={"1234567"} ref={imonumber}/>
                        <span className="input-group-text">Регистровый номер</span>
                        <input type="text" className="form-control" ref={registrynumber}
                               onChange={() => handleOnChange()}/>
                        <span className="input-group-text">Реестровый номер</span>
                        <input type="text" className="form-control" ref={reestrnumber}
                               onChange={() => handleOnChange()}/>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Контакты судна</span>
                        <input type="text" className="form-control" ref={contact} onChange={() => handleOnChange()}/>
                    </div>

                    <div className="input-group input-group-sm mb-1 mt-3">
                        <span className="input-group-text">Приход в порт</span>
                        <input type="text" className="form-control" ref={inport} onChange={() => handleOnChange()}/>
                        <span className="input-group-text">страна</span>
                        <select className="form-select" ref={incountry} defaultValue={0}>
                            {countries.map((c: CountryType) => <option value={c.id}
                                                                       key={c.id}>{c.name}</option>)}
                        </select>
                        <span className="input-group-text">из порта</span>
                        <input type="text" className="form-control" ref={fromport} onChange={() => handleOnChange()}/>
                        <span className="input-group-text">страна</span>
                        <select className="form-select" ref={fromcountry} defaultValue={0}>
                            {countries.map((c: CountryType) => <option value={c.id}
                                                                       key={c.id}>{c.name}</option>)}
                        </select>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Отход в порт</span>
                        <input type="text" className="form-control" ref={outport} onChange={() => handleOnChange()}/>
                        <span className="input-group-text">страна</span>
                        <select className="form-select" ref={outcountry} defaultValue={0}>
                            {countries.map((c: CountryType) => <option value={c.id}
                                                                       key={c.id}>{c.name}</option>)}
                        </select>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Цель захода</span>
                        <input type="text" className="form-control" ref={target} onChange={() => handleOnChange()}/>
                        <span className="input-group-text">место</span>
                        <input type="text" className="form-control" ref={place} onChange={() => handleOnChange()}/>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Груз</span>
                        <input type="text" className="form-control" ref={cargo} onChange={() => handleOnChange()}/>
                        <span className="input-group-text">кол-во (т/м<sup>3</sup>)</span>
                        <input type="text" className="form-control" ref={count} onChange={() => handleOnChange()}/>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Ориентировочная дата и время прибытия</span>
                        <input type="datetime-local" className="form-control" ref={indatetime}
                               onChange={() => handleOnChange()}/>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                                <span
                                    className="input-group-text">Ориентировочная дата начала и окончания погрузки</span>
                        <input type="date" className="form-control" ref={startloadtime}
                               onChange={() => handleOnChange()}/>
                        <span className="input-group-text">&nbsp;-&nbsp;</span>
                        <input type="date" className="form-control" ref={endloadtime}
                               onChange={() => handleOnChange()}/>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Адрес места погрузки</span>
                        <input type="text" className="form-control" ref={addressobject}
                               onChange={() => handleOnChange()}/>
                        <span className="input-group-text">регион</span>
                        <select className="form-select" ref={addressobjectregion} defaultValue={0}
                                onChange={() => handleOnChange()}>
                            <option value={0} key={0} disabled={true}></option>
                            {regions.map((r: RegionType) => <option value={r.id}
                                                                    key={r.id}>{r.name}</option>)}
                        </select>
                    </div>
                    <div className="input-group input-group-sm mb-1 mt-3">
                        <span className="input-group-text">Оператор</span>
                        <span className="input-group-text">ИНН</span>
                        <input type="number" className="form-control" id="innoperator" name="innoperator" min={1}
                               max={999999999999} step={1}
                               onChange={handleInnChange} placeholder={"1234567890"}/>
                        <span className="input-group-text">наименование</span>
                        <input className="form-control" ref={nameOperator} disabled name={"nameoperator"}/>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Контакты оператора</span>
                        <input type="text" className="form-control" ref={contactoperator}
                               onChange={() => handleOnChange()}/>
                    </div>
                    <div className="input-group input-group-sm mb-1 mt-3">
                        <span className="input-group-text">Судовладелец</span>
                        <span className="input-group-text">ИНН</span>
                        <input type="number" className="form-control" id="innmaster" name="innmaster" min={1}
                               max={999999999999} step={1}
                               onChange={handleInnChange} placeholder={"1234567890"}/>
                        <span className="input-group-text">наименование</span>
                        <input type="text" className="form-control" disabled ref={nameMaster}/>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Контакты судовладельца</span>
                        <input type="text" className="form-control" ref={contactmaster}
                               onChange={() => handleOnChange()}/>
                    </div>
                    <div className="input-group input-group-sm mb-1 mt-3">
                        <span className="input-group-text">Грузоотправитель</span>
                        <span className="input-group-text">ИНН</span>
                        <input type="number" className="form-control" id="inncargo" name="inncargo" min={1}
                               max={999999999999} step={1}
                               onChange={handleInnChange} placeholder={"1234567890"}/>
                        <span className="input-group-text">наименование</span>
                        <input className="form-control disabled" ref={nameCargo} disabled name={"namecargo"}/>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Контакты грузоотправителя</span>
                        <input type="text" className="form-control" ref={contactcargo}
                               onChange={() => handleOnChange()}/>
                    </div>
                    <div className="input-group input-group-sm mb-1 mt-3">
                        <span className="input-group-text">Грузополучатель</span>
                        <span className="input-group-text">ИНН</span>
                        <input type="number" className="form-control" id="innconsignee" name="innconsignee" min={1}
                               max={999999999999} step={1}
                               onChange={handleInnChange} placeholder={"1234567890"}/>
                        <span className="input-group-text">наименование</span>
                        <input className="form-control disabled" ref={nameConsignee} disabled name={"nameconsignee"}/>
                    </div>

                </div>


                <div className="ModalFooter">
                    <div className={"Button Primary" + (canSave ? "" : " Disabled")} onClick={() => {
                        if (canSave) createRequest();
                    }}>Создать
                    </div>
                    <div className="Button" style={{marginLeft: "10px"}} onClick={() => hideMe()}>Отмена</div>
                </div>
            </div>
        </div>
    );
}

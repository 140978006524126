import React, {ReactElement, useState} from "react";
import {Utils} from "../../utils/Utils";
import {TabOutRequests} from "./TabOutRequests";
import {EmployeeType} from "../../types/EmployeeType";
import {TabPassports} from "./TabPassports";
import {TabInRequests} from "./TabInRequests";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRightFromBracket,
    faArrowRightToBracket,
    faChartLine,
    faFile,
    faPassport
} from "@fortawesome/free-solid-svg-icons";
import {TabOutNotifications} from "./TabOutNotifications";
import {TabDocuments} from "./TabDocuments";
import {TabAnalytics} from "./TabAnalytics";

export function WaterIndex(props: any): ReactElement {
    const me: EmployeeType = props.me;

    const [activeTab, setActiveTab] = useState<number>(
        Utils.hasRole("water_sti_request")
            ? 0
            : (Utils.hasRole("water_sti")
                ? 1
                : (Utils.hasRole("water_ptb_request")
                    ? 2
                    : (Utils.hasRole("water_passport")
                        ? 3
                        : 0))));

    return (
        <div className={'main-page'}>
            <div style={{
                display: "flex",
                marginTop: "16px",
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
                backgroundColor: "rgb(255, 255, 255)",
                height: "44px",
                padding: "8px 12px 8px 12px",
                borderBottom: "1px solid var(--otb-background)"
            }}>

                {Utils.hasRole("water_sti_request")
                    ? <div className={"NavLink" + (activeTab === 0 ? " Active" : "")} onClick={() => setActiveTab(0)}>
                        <FontAwesomeIcon icon={faArrowRightFromBracket}
                                         style={{height: "16px", width: "16px", paddingRight: "6px"}}/>
                        Исходящие заявки
                    </div>
                    : ""
                }

                {Utils.hasRole("water_sti")
                    ? <div className={"NavLink" + (activeTab === 1 ? " Active" : "")} onClick={() => setActiveTab(1)}>
                        <FontAwesomeIcon icon={faArrowRightFromBracket}
                                         style={{height: "16px", width: "16px", paddingRight: "6px"}}/>
                        Исходящие уведомления
                    </div>
                    : ""
                }

                {Utils.hasRole("water_ptb_request")
                    ? <div className={"NavLink" + (activeTab === 2 ? " Active" : "")} onClick={() => setActiveTab(2)}>
                        <FontAwesomeIcon icon={faArrowRightToBracket}
                                         style={{height: "16px", width: "16px", paddingRight: "6px"}}/>
                        Входящие заявки
                    </div>
                    : ""
                }

                {Utils.hasRole("water_passport")
                    ? <div className={"NavLink" + (activeTab === 3 ? " Active" : "")} onClick={() => setActiveTab(3)}>
                        <FontAwesomeIcon icon={faPassport}
                                         style={{height: "16px", width: "16px", paddingRight: "6px"}}/>
                        Паспорта ОТБ ТС
                    </div>
                    : ""
                }

                {Utils.hasRole("water") && Utils.hasRole("water_ptb")
                    ?
                    <div className={"NavLink" + (activeTab === 4 ? " Active" : "")} onClick={() => setActiveTab(4)}>
                    <FontAwesomeIcon icon={faFile}
                                     style={{height: "16px", width: "16px", paddingRight: "6px"}}/>
                    Документы
                </div>
                    : ""
                }

                {Utils.hasRole("water_analytics")
                    ?
                    <div className={"NavLink" + (activeTab === 5 ? " Active" : "")} onClick={() => setActiveTab(5)}>
                        <FontAwesomeIcon icon={faChartLine}
                                         style={{height: "16px", width: "16px", paddingRight: "6px"}}/>
                        Аналитика
                    </div>
                    : ""
                }
            </div>

            <div className="tab-content">

                {Utils.hasRole("water_sti_request") && activeTab === 0 ? <TabOutRequests me={me}/> : ""}
                {Utils.hasRole("water_sti") && activeTab === 1 ? <TabOutNotifications me={me}/> : ""}
                {Utils.hasRole("water_ptb_request") && activeTab === 2 ? <TabInRequests me={me}/> : ""}
                {Utils.hasRole("water_passport") && activeTab === 3 ? <TabPassports me={me}/> : ""}

                {Utils.hasRole("water") && Utils.hasRole("water_ptb") && activeTab === 4 ? <TabDocuments me={me}/> : ""}
                {Utils.hasRole("water_analytics") && activeTab === 5 ? <TabAnalytics me={me}/> : ""}
            </div>
        </div>
    )
}
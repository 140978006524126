import React, {ReactElement} from "react";

export function StatusContract(props: any): ReactElement {
    return (
        <div className={"m-1"}>
            <label className="text-dark">Статусы контрактов:&nbsp;</label>
            <p className={"status-list d-inline"}>
                <span className="badge bg-light">Новый</span>
                <span className="badge primary">Активен</span>
                <span className="badge warning">Замечания</span>
                <span className="badge danger">Ошибки</span>
                <span className="badge success">Завершен</span>
            </p>
        </div>
    )
}
import React, {ChangeEvent, ReactElement, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {REST} from "../../REST";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";

export function PublicPage(props: any) : ReactElement {
    const {id} = useParams<string>();
    const [currentId, setCurrentId] = useState<string>();

    const handle = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentId(e.target.value);
    }

    if(id === undefined) {
        return (
            <div className={"ModalBackground"}>
                <div className={"Modal"}>
                    <div className={"ModalHeader"} style={{justifyContent: "space-between"}}>
                        Публичная страница МОРРЕЧФЛОТ
                    </div>
                    <div className={"ModalBody"}>

                        <div>
                            <div className="input-group input-group-sm mb-1">
                                <span className="input-group-text">Код акта досмотра</span>
                                <input className="form-control" id="id" onChange={handle} defaultValue={""} placeholder={"123456789"}/>
                            </div>
                        </div>

                    </div>
                    <div className={"ModalFooter"}>
                        <div className="Button Primary"
                             onClick={() => {
                                 window.location.href = "/water/public/" + currentId!;
                             }}>
                            <FontAwesomeIcon icon={faEye}/>&nbsp;Показать информацию
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={"ModalBackground"}>
            <div className={"Modal"}>
                <div className={"ModalHeader"} style={{justifyContent: "space-between"}}>
                    Публичная страница МОРРЕЧФЛОТ
                </div>
                <div className={"ModalBody"}>

                    <div>
                        <div className="input-group input-group-sm mb-1">
                            <span className="input-group-text">Код акта досмотра</span>
                            <div className="form-control" id="id">{id}</div>
                        </div>
                    </div>

                </div>
                <div className={"ModalFooter"}>

                </div>
            </div>
        </div>
    )
}